import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"

import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen7 from '../../img/generator7.jpg';
import { Helmet } from "react-helmet";
function Generator7() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>100 кВт 125kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо-дизель 160л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>17 л в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>220/380 50/60 210</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>250 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>2400 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2990 х 1180 х 1640</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>4 900 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>6 100руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>2 300 руб</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор AIRMAN SDG 150 S"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 100 кВт AIRMAN SDG 150 S</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>100 кВт 125kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо-дизель 160л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>17 л в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>220/380 50/60 210</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>250 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>2400 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2990 х 1180 х 1640</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>4 900 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>6 100руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>2 300 руб</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen7} alt='Аренда дизельного генератора 100 кВт AIRMAN SDG 150 S'></img>
            </div>
            <div className='item__desk'>
                <p className='Article__container-item'>Выполняя разовые работы, предприятия, небольшие компании и частные лица сталкиваются с проблемой отсутствия подачи электроэнергии от электрической сети. Оптимальным решением этой задачи будет аренда автономной электростанции. В последнее время особо актуальной и востребованной в Москве стала аренда генераторов на 100 квт.</p>
                <p className='Article__container__subtitle'>Выгодная аренда генераторов 100 кВт</p>
                <p className='Article__container-item'>Арендованный дизельный генератор из компании «Машины и механизмы» обеспечит вас электроэнергией на непродолжительный период времени:</p>
                <p className='Article__container-list'>при выполнении ремонтных работ, где требуется использование электроинструментов;</p>
                <p className='Article__container-list'>при аварийных отключениях электричества в жилых домах, офисах, на предприятиях;</p>
                <p className='Article__container-list'>на стройплощадках, где временно отсутствует электропитание;</p>
                <p className='Article__container-list'>для организации выездных рекламных и развлекательных мероприятий.</p>
                <p className='Article__container-item'>Компания «Машины и механизмы» располагает широким ассортиментом продукции, предлагая на выгодных условиях аренду дизель генератора на 100 кВт в Москве.</p>
                <p className='Article__container-item'>Опытные менеджеры помогут индивидуально для каждого клиента подобрать модель генератора по мощности, необходимой для обеспечения электричеством их объектов.</p>
                <p className='Article__container-item'>Передача электрооборудования во временное пользование осуществляется на срок, необходимый заказчику. Наши специалисты дадут консультацию по правилам эксплуатации оборудования.</p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator7;