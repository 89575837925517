import React from "react"
import Catalog from "../components/catalog"
import HeaderSubSpace from "../components/helpcomponents/headerspace"
import {Link} from "react-router-dom";
import Popup from "../components/popup1";
import { useState } from "react";
import Moreinfo from "../components/moreinfo"
import Generator4 from "../img/selltech1.jpg"
const MainSellTech =()=>{
    const handleButtonClick = () => {
        window.scrollTo(0, 0);;
    }  
        const [popupActive, setPopUpActive] = useState(false)
        
        return(
            <> 
                <HeaderSubSpace/>
                <Catalog/>
                <div className="selltech__wrapper">
                    <div className="selltech__tittle">Продажа Б/У техники</div>
                    <div className="selltech__container">
                    <div className="gS__container-item1">
                                    <div className="gS__item__top" >
                                            <Link to='/used-maschinery1' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator4} className="gs__item__top-pic" alt="продажа"></img>
                                            </Link>
                                    </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/used-maschinery1' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Airman SDG 100 S</p></Link>
                                            <div className="gs__item__bottom-power">64 кВт 80 kVa</div>
                                            <div className="gs__item__bottom-price">
                                                <div className="gs__item__bottom-price-t">Продажа</div>
                                                <div className="gs__item__bottom-price-c">от 610 000 руб</div>
                                            </div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Купить</button>                            
                                </div>
                    </div>
                </div>     
                <Moreinfo/>
                     
                <Popup  active={popupActive} setActive={setPopUpActive} />
            </>
           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        )
    
}

export default MainSellTech