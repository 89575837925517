import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article16 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Аренда генераторов — короткий путь к решению ваших проблем</div>
                    <div className='Articles__container1'>
                        <p className='Article__container-item'>Аренда дизельных генераторов для обеспечения временного или постоянного энергоснабжения объектов без подключения к сетям централизованной подачи электроэнергии используется повсеместно: производство и строительные площадки, киносъёмки, спортивные и праздничные мероприятия и многие других ситуации.</p>
                        <p className='Article__container-item'>Наличие в «арсенале» своей компании собственного дизельного генератора — это, пожалуй, мечта каждого энергетика. Безусловно, это имеет целый ряд своих плюсов перед арендой генератора, главный из которых — возможность быстро решить проблемы при возникновении аварийной ситуации внутренним ресурсами фирмы. Но и минусы тоже присутствуют — приобрести электростанцию не самое дешёвое удовольствие, к тому же последующие затраты на его ремонт и техническое обслуживания вряд ли будут полностью оправданы.</p>
                        <p className='Article__container-item'>Да и не редко возникает такая ситуация, что объектов на фирме несколько и каждому нужен свой дизельгенератор, причём, зачастую, различной мощности. В таком случае приобретение нескольких электростанций неизбежно приводит к множеству проблем. Именно в подобной ситуации поневоле понимаешь, что аренда дизельгенератора гораздо менее хлопотное дело. </p>
                        <p className='Article__container-item'>Особенно актуальна эта услуга в том случае, если обеспечить подачу энергии необходимо в срочном порядке, а необходимых средств на покупку электростанции у фирмы на данный момент нет — в таком случае аренда генератора позволит вам быстро решить возникшую проблему за значительно меньшие деньги и гораздо быстрее, ведь в случае покупки времени в любом случае потребуется гораздо больше — приобретение, доставка и пусконаладочные работы могут занять времени от несколько дней до пары недель.</p>
                        <p className='Article__container-item'>В том время как аренда электростанции в нашей компании — дело буквально нескольких часов, за которые вы получите на свой объект надёжное и полностью готовое к работе оборудование по приемлемым ценам. Плюс ко всему, аренда генератора — это комплексная услуга, в стоимость которой, помимо непосредственной аренды оборудования, включены также полное техническое обслуживание, ремонт и отладка, а так же своевременное обеспечение топливом, поэтому вам не придётся даже затрачивать ресурсы на обучение персонала.</p>
                        <p className='Article__container-item'>Все наши клиенты одинаково важны для нас, и мы всегда стараемся предоставлять только самое лучшее и надёжное оборудование, но так случается, что любая техника может рано или поздно выйти из строя. Однако в случае возникновения подобной форс-мажорной ситуации, вы можете быть уверенны в том, что это с минимальными потерями отразиться на работе вашей компании — вам не придётся ждать окончания ремонтных работ, мы просто оперативно заменим электростанцию на аналогичную рабочую установку.</p>
                        <p className='Article__container-item'>Аренда генераторов в нашей компании, это, помимо прочего, ещё и вклад в будущее развитие вашего бизнеса — ведь любому производству может в один прекрасный день потребоваться большая мощность, и только в случае аренды вы сможете быстро и без лишних сложностей заменить генератор — в каталоге нашей компании представлены дизельные генераторы в достаточно широком мощностном диапазоне.</p>
                        <p className='Article__container-item'>Аренда электростанций для наших клиентов — это простое занятие не требующее от вас никаких технических навыков — наши специалисты окажут вам всю необходимую помощь в выборе конкретных моделей с необходимыми именно вам характеристиками и учтут все пожелания относительно уровня шума и помогут рассчитать необходимую номинальную мощность.</p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article16 ;