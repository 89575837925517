import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen2 from '../../img/generator2.jpg';
import { Helmet } from "react-helmet";
function Generator2() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>24 кВт 30 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>атм. дизель 45 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>5.5 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/220 50/60 45</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>82 литра</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>800 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>1900 х 990 х 860</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>2 850 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>3 600 руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>1 100 руб</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор DENYO DCA 35"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 20 кВт DENYO DCA 35</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>24 кВт 30 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>атм. дизель 45 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>5.5 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/220 50/60 45</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>82 литра</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>800 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>1900 х 990 х 860</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>2 850 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>3 600 руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>1 100 руб</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen2} alt='Аренда дизельного генератора 20 кВт DENYO DCA 35'></img>
            </div>
            <div className='item__desk'>
                <p className='Article__container-item'>Бывают случаи, когда возникает авария на централизованных электросетях, нужен ремонт с независимым источником питания, необходим обогрев и энергоснабжение построек или ремонт строительных объектов на удалённых участках от сети.</p>
                <p className='Article__container-item'>В данном исходе вам просто необходим дизельный генератор на 24 кВт Denyo DCA 35. Чаще всего эти ситуации носят временный характер и покупка специализированногооборудования необязательна. Решением проблемы может стать аренда на время проведения ремонтных работ.</p>
                <p className='Article__container__subtitle'>Аренда дизель генератора на 20 кВт, цена и выгода</p>
                <p className='Article__container-item'>Аренду источников электропитания на 20 кВт может предоставить наша компания. Мы выполняем весь спектр услуг — доставка на место, установка и работа специалиста, а также прокат иных необходимых товаров. Также возможна срочнаяаренда. В наличии имеются мощные и надёжные генераторы от ведущих зарубежных производителей.</p>
                <p className='Article__container-item'>При аренде генератора на 20кВт, можно сэкономить существенную сумму на покупке энергоустановки, потому что даже год пользования у нас обойдётся дешевле, чемпокупка.</p>
                <p className='Article__container-item'>Вы платите только за аренду оборудования, а всё техобслуживание и ремонт возлагается на нас.Компания предлагает аренду дизель генератора на 20 квт, цена которого очень выгодна для наших клиентов.</p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator2;