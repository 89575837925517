import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"

import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import kom1 from '../../img/kompressor1.jpg'
import { Helmet } from "react-helmet";
function Kompressor1() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
        <tr>
            <td>Двигатель</td>
            <td>YANMAR 4TNV88-BDHKS</td>
        </tr>
                    <tr>
            <td>Производительность	</td>
            <td>5.2.0 м3 в минуту</td>
        </tr>
                    <tr>
            <td>Рабочее давление</td>
            <td>7.5 кг/см2</td>
        </tr>
                    <tr>
            <td>Расход топлива под нагрузкой</td>
            <td>100% - 10 л. в час, 50% - 5.5 л. в час</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>90 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1010 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2200 х 1450 х 1520</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки с НДС</td>
            <td>3 500 р</td>
        </tr>
                                                        <tr>
            <td>в сутки без НДС</td>
            <td>2 900 р</td>
        </tr>
                                                    
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный компрессор Airman PDS 185 SC"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного компрессора Airman PDS 185 SC</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Производительность	</td>
            <td>5.2 м3 в минуту</td>
        </tr>
                    <tr>
            <td>Рабочее давление</td>
            <td>7.5 кг/см2</td>
        </tr>
                    <tr>
            <td>Расход топлива под нагрузкой</td>
            <td>100% - 10 л. в час, 50% - 5.5 л. в час</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>90 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1010 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2200 х 1450 х 1520</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки с НДС</td>
            <td>3 500 р</td>
        </tr>
                                                        <tr>
            <td>в сутки без НДС</td>
            <td>2 900 р</td>
        </tr>
                                                    
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={kom1} alt='Аренда дизельного компрессора Airman PDS 185 S'></img>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Kompressor1;