import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article8 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Аренда дизельного компрессора AIRMAN</div>
                    <div className='Articles__container1'>
                        <p className="Article__container-item">
                        После заключения Договора аренды компрессора мы можем доставить компрессор на объект, дать необходимые консультации по её эксплуатации, если необходимо, то оказать услуги по подключению дизельного компрессора. Плановое техническое обслуживание арендуемого дизельного компрессора осуществляется нами, и не требует с Вашей стороны дополнительных расходов.
                        </p>
                        <p className="Article__container__subtittle">
                            Устройство винтового дизельного компрессора
                        </p>
                        <p className="Article__container-item">
                            Стационарный винтовой дизельный компрессор состоит из винтового компрессорного элемента, погруженного в масло, приводимого в движение дизельным двигателем. Рама компрессора покрыта надежным кожухом, который защищает от различных внешних воздействий и поглощает шум, производимый установкой. Кожух компрессора спроектирован так, что можно легко и удобно получить доступ к обслуживаемым узлам.
                        </p>
                        <p className="Article__container__subtittle">
                            Система контроля стационарного винтового компрессора
                        </p>
                        <p className="Article__container-item">
                            Поддержание требуемой мощности производиться клапанами обратной связи, которые отслеживают рабочее давление в ресивере. При возрастании потребления сжатого воздуха система определяет падение давления на выходе дизельного компрессора, увеличивает число оборотов двигателя и открывает входной клапан. При обратной ситуации, когда потребление падает, то соответственно обороты двигателя падают, входной клапан закрывается. Такая система точно отслеживает требуемое на выходе дизельного компрессора давление и подстраивает внутреннюю мощность для обеспечения заданного уровня.
                        </p>
                        <p className="Article__container-item">
                            Также система контроля дизельного компрессора отслеживает его состояние и если возникает неисправность или выход параметров за рабочие границы, то выдается предупреждающее сообщение. Система отслеживает такие параметры как: температура выходящего воздуха; давление масла в компрессоре; состояние охлаждающей жидкости; состояние двигателя и другие. Если после предупреждения проблема не была решена, то происходит аварийная остановка винтового дизельного компрессора.
                        </p>
                        <p className="Article__container__subtittle">
                            Основные характеристики стационарного винтового дизельного компрессора
                        </p>
                        <p className="Article__container-list">
                            Роторные винты проходят тщательную термическую и механическую обработку с минимальными допусками.
                        </p>
                        <p className="Article__container-list">
                            Дизельный компрессор спроектирован с учетом требований защиты окружающей среды. Его уровень шума ниже пределов ЕРА и соответствуют всем требованиям ЕЭС.
                        </p>
                        <p className="Article__container-list">
                            Благодаря высокой эффективности двигателя, позволяющей существенно снизить затраты, выбросы в окружающую среду минимальны.
                        </p>
                        <p className="Article__container-list">
                            Автоматически регулируемая мощность в диапазоне от 0 до 100%. Система контроля оптимизирует скорость вращения в зависимости от нагрузки, тем самым повышая эффективность и уменьшая износ.
                        </p>
                        <p className="Article__container-list">
                            Пульт управления располагается сзади дизельного компрессора для обеспечения защиты оператора.
                        </p>
                        <p className="Article__container-list">
                            Для упрощения эксплуатации стационарный винтовой дизельный компрессор оборудован широко открывающимися дверями, обеспечивающих легкий доступ к обслуживаемому оборудованию.
                        </p>
                        <p className="Article__container-list">
                            Каркас выполнен из прочных материалов, окрашенных электростатической краской и неподверженных повреждению ржавчиной.
                        </p>
                        <p className="Article__container-list">
                            Постоянный контроль системой защите предотвращает от выхода из строя оборудования.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article8;