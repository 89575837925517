import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import slt1 from "../../img/selltech1.jpg"
function Selltech1() {
    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <p>
Дизельный генератор Airman SDG 100 S имеет номинальную выходную мощность 64 кВт. Он оснащен восоконадежным дизельным двигателем марки ISUZU. Прочная рама и шумопоглащающий закрытый кожух, устойчивый к погодным воздействиям, позволяет электростанции работать круглый год под открытым небом. Стационарная конструкция генератора делает возможным эксплуатироваться его круглосуточно без остановки. В добавок к этому его можно использовать в черте города вблизи жилых домов благодаря низкому уровню шума. Аренда электростанции Airman SDG 100 S – это правильный выбор для средних промышленных задач и для различных отраслей строительного производства.
        </p>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    
    return (
        <>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Продажа дизельного генератора 64 кВт Airman SDG 100 S</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <p >
                                Дизельный генератор Airman SDG 100 S имеет номинальную выходную мощность 64 кВт. Он оснащен восоконадежным дизельным двигателем марки ISUZU. Прочная рама и шумопоглащающий закрытый кожух, устойчивый к погодным воздействиям, позволяет электростанции работать круглый год под открытым небом. Стационарная конструкция генератора делает возможным эксплуатироваться его круглосуточно без остановки. В добавок к этому его можно использовать в черте города вблизи жилых домов благодаря низкому уровню шума. Аренда электростанции Airman SDG 100 S – это правильный выбор для средних промышленных задач и для различных отраслей строительного производства.
                                </p>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Описание</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<div className="card__table-wrapper">
                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                        <td>Мощность</td>
                        <td>64 кВт 80 kVa</td>
                    </tr>
                                        <tr>
                        <td>Двигатель</td>
                        <td>турбо дизель 100 л.с.</td>
                    </tr>
                                        <tr>
                        <td>Расход под нагр. 75% (л. в час)</td>
                        <td>13 л в час</td>
                    </tr>
                                        <tr>
                        <td>Напряжение/Частота /Сила тока</td>
                        <td>220/380 50/60 122</td>
                    </tr>
                                        <tr>
                        <td>Объем бака (л)</td>
                        <td>225 литров</td>
                    </tr>
                                        <tr>
                        <td>Вес</td>
                        <td>1890 кг</td>
                    </tr>
                                        <tr>
                        <td>Габариты</td>
                        <td>2600 х 1000 х 1400</td>
                    </tr>
                                    </table>
                                </div>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Купить</button>
                </div>
                <img className='item__image' src={slt1} alt='продажа'></img>
            </div>
            <div className='item__desk'>
                <p className='Article__container-item'>Дизельный генератор Airman SDG 100 S имеет номинальную выходную мощность 64 кВт. Он оснащен восоконадежным дизельным двигателем марки ISUZU. Прочная рама и шумопоглащающий закрытый кожух, устойчивый к погодным воздействиям, позволяет электростанции работать круглый год под открытым небом. Стационарная конструкция генератора делает возможным эксплуатироваться его круглосуточно без остановки. В добавок к этому его можно использовать в черте города вблизи жилых домов благодаря низкому уровню шума. Аренда дизельгенератора Airman SDG 100 S – это правильный выбор для средних промышленных задач и для различных отраслей строительного производства.</p>
                <p className='Article__container-item'>Дизельные генераторы SDG от компании AIRMAN - это очень тихие, удобные в управлении и техническом обслуживании современные генераторы.</p>
                <p className='Article__container-item'>Генераторы AIRMAN разрабатываются собственной командой инженеров, используя самые передовые достижения, накопленные в результате большого опыта работы в данной сфере. Шум дизельного генератора во время работы минимален, поскольку в генераторе используются современные тихие двигатели, а также они оснащены специально разработанной выхлопной системой. Общая вибрация генераторов также уменьшена до минимума за счет внедрения специальных поддерживающих устройств рамы, двигателя и глушителя. Генераторы AIRMAN имеют высокую производительность при минимальных расходах топлива.</p>
                <p className='Article__container-item'>Основной задачей фирмы AIRMAN является создание продукции, которая позволит потребителю решить свои задачи, а не думать, как заставить оборудование работать. При разработке своей продукции AIRMAN всегда учитывает новые идеи в области дизайна и конструирования машин. Вся продукция AIRMAN, имеет сертификат качества ISO 9001 и соответствует всем требованиям современного рынка. Имеет смысл отметить, что многие дорогостоящие опции, которые предлагают другие производители, являются стандартными для продукции AIRMAN. А именно: шумоизоляционный корпус, гидроразводка и клапан для подключения внешнего бака, реле утечки электричества, система параллельного подключения, автоматическое удаление воздуха из топливной системы и многое другое</p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
}

export default Selltech1;