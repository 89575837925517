import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
const Moreinfo =()=>{

    const form = useRef();
    const [successActive, setSuccessActive] = useState(false)
    function animation(){
        setSuccessActive(true)
        setTimeout(()=>{setSuccessActive(false)},4000)
    }
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_s2vi111', 'template_2mq1hf4', form.current, '07aR0p5Xyc5vKrCKc')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

        return (
            <div className='moreInfo__container'>
                <div className="moreinfo__row1">
                    <div className='moreinfo__row1-tittle'>
                        Нужно больше информации?
                    </div>
                    <div className='moreinfo__row1-subtittle'>
                        Отправьте свои контактные данные на нашу почту 7737177@mail.ru и мы свяжемся с вами

                    </div>
                </div>
                <div className="moreinfo__row2">
                <form ref={form} className="moreinfo__form" onSubmit={sendEmail}>
                    <div className='moreinfo__form-div'>
                        <input className="moreinfo__form-item" placeholder="Ваше имя" type="text" name="name" ></input>
                        <input className="moreinfo__form-item infmail"placeholder="E-mail" type="email" name="email"></input>
                        <input className="moreinfo__form-item inftel" placeholder="+7 (___) ___-__-__" type="phone" name="number"></input>  
                    </div>
                    <div>
                        <textarea className="moreinfo__form-item textarea1" placeholder="Задайте вопрос" name="text"></textarea>     
                        <div className="moreinfo__warning">Нажимая на кнопку, вы даете согласие на обработку своих персональных данных и соглашаетесь с политикой конфиденциальности.</div>                
                        <input className="moreinfo__button" type="submit" value="Отправить" onClick={()=>animation()}></input> 
                        <div className={successActive? "success__message":"success__message nonactive"}>Сообщение отправлено</div>

                    </div>
                                   
                </form>
                </div>
            </div>
        )
    
}
 
export default Moreinfo;