import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import React from 'react';
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import kom3 from '../../img/kompressor3.jpg'
import { Helmet } from "react-helmet";
function Kompressor3() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Двигатель</td>
            <td>ISUZU DD-4BG1T</td>
        </tr>
                    <tr>
            <td>Производительность	</td>
            <td>11 м3 в минуту</td>
        </tr>
                    <tr>
            <td>Рабочее давление</td>
            <td>7.1 кг/см2</td>
        </tr>
                    <tr>
            <td>Расход топлива под нагрузкой</td>
            <td>100% - 22 л. в час, 50% - 12 л. в час</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>180 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1700 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>3800 х 1800 х 1780</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки с НДС</td>
            <td>6 000 р</td>
        </tr>
                                                        <tr>
            <td>в сутки без НДС</td>
            <td>4 900 р</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный компрессор Airman PDS 390 SC-W"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного компрессора Airman PDS 390 SC-W</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Двигатель</td>
            <td>ISUZU DD-4BG1T</td>
        </tr>
                    <tr>
            <td>Производительность	</td>
            <td>11 м3 в минуту</td>
        </tr>
                    <tr>
            <td>Рабочее давление</td>
            <td>7.1 кг/см2</td>
        </tr>
                    <tr>
            <td>Расход топлива под нагрузкой</td>
            <td>100% - 22 л. в час, 50% - 12 л. в час</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>180 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1700 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>3800 х 1800 х 1780</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки с НДС</td>
            <td>6 000 р</td>
        </tr>
                                                        <tr>
            <td>в сутки без НДС</td>
            <td>4 900 р</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={kom3} alt="Аренда дизельного компрессора Airman PDS 390 S"></img>
            </div>
            <div className='item__desk'>
                <p className='article__container__subtitle'>Аренда дизельного компрессора PDS 390 S</p>
                <p className='article__container-item'>Дизельный компрессор Airman PDS 390 S имеет номинальную выходную производительность 11.0 м3/мин. Ниже приведены основные характеристики Японского дизельного компрессора.</p>
                <p className='article__container-list'>Роторы проходят высокоточную механическую и термическую обработку, осуществляемую автоматикой.</p>
                <p className='article__container-list'>Низкое шумовое загрязнение, позволяющее работать вблизи жилых домов.</p>
                <p className='article__container-list'>Благодаря высокой эффективности мощность двигателя низкая, что существенно сокращает выбросы в атмосферу. Дизельный компрессор оснащен устройством холостого хода для прогрева перед работой.</p>
                <p className='article__container-list'>Автоматическая регулировка мощности в диапазоне от 0 до 100%. Конструкция устройства исключает помпаж двигателя, точно отслеживая необходимость в потребляемой мощности и подстраивая обороты компрессора.</p>
                <p className='article__container-list'>Пульт расположен сзади дизельного компрессора в целях дополнительной защиты оператора, отслеживает работу машины. Также устройство оснащено удобными открывающимися дверцами для упрощения обслуживания.</p>
                <p className='article__container-list'>Прочная конструкция из стали покрыта непроводящей краской, защищающей от влияния погодных условий на метал.</p>
                <p className='article__container-list'>Постоянный контроль основных параметров работы дизельного компрессора: сильного выпуска воздуха, перегрева охладителя, недостаточного давления масла в двигателе и т.д. – делает работы стабильной и защищает оператора от нежелательных воздействий.</p>
                <p className='article__container-item'>И из всего многообразия представленного на рынке компрессорного оборудования мы предлагаем Вам наиболее качественное и надежное. Аренда дизельного компрессора у нас, равно как и другой техники, это приобретение надежного оборудования, которое будет бесперебойно служить Вам долгое время.</p>
                <p className='article__container-item'>Компания HOKUETSU INDUSTRIES CO., LTD (AIRMAN) является одним из мировых лидеров в производстве компрессоров. Доля компании на мировом рынке около 15%. В продукции AIRMAN используются собственные разработки гидравлики и компрессии воздуха. Добавляя сюда систему электронного контроля, получаем оборудование высочайшего класса и надежности известное по всему миру!</p>
                <p className='article__container-item'>Дизельный компрессор AIRMAN состоит из одноступенчатого винтового маслонаполненного компрессорного элемента производства HOKUETSU INDUSTRIES CO., который приводится в движение через упругую муфту от дизельного двигателя марки ISUZU. Дизельный компрессор имеет прочную раму надежно закрытую шумопоглощающим кожухом, который спланирован так, что удобно производить работы по техническому обслуживанию.</p>
                <p className='article__container-item'>Система управления, реагирует на изменение рабочего давления в ресивере и устанавливает рабочее давление специальным регулировочным клапаном. При возникновении со стороны потребителя необходимости увеличить потребление сжатого воздуха, система управления фиксирует снижение давления на выходе из дизельного компрессора и дает команду на увеличение числа оборотов двигателя и на открытие входного клапана. При уменьшении потребности в сжатом воздухе, система фиксирует повышение рабочего давления и уменьшает число оборотов двигателя и перекрывает входной клапан. Таким образом, система управления гарантирует точное соответствие произведенного и потребленного сжатого воздуха.</p>
                <p className='article__container-item'>Винтовой компрессор является одним из наиболее производительных экономичных и долговечных типов компрессорного оборудования. Такой дизельный компрессор обладает значительно большим ресурсом и идеально подходит для длительной непрерывной эксплуатации. Его обслуживание нужно производить лишь раз в год. Применение винтового компрессора позволяет существенно снизить расход масла, затраты на потребляемую энергию и техническое обслуживание.</p>
                <p className='article__container-item'>Область применения дизельного компрессора, используемого для сжатия и подачи воздуха под давлением, весьма широка. Компрессоры активно используются в технологических процессах различных отраслей промышленности: нефтехимической, химической, строительной, пищевой, горнодобывающей и других. Например, передвижной дизельный компрессор пользуется высоким спросом при выполнении дорожно-строительных и ремонтных работ для подключения пневмоинструмента.</p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Kompressor3;