import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article3 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Автономные дизельные генераторы</div>
                    <div className='Articles__container1'>
                    <p className='Article__container-item'>Автономный дизельный генератор является основной «рабочей лошадкой» там, где по каким-либо причинам недоступно централизованное электроснабжение, поэтому нет ничего удивительного в популярности дизельных электростанций. Кроме того, они обеспечивают более низкую стоимость вырабатываемой электроэнергии, что способствует быстрой окупаемости техники.</p>
                    <p className='Article__container-item'>Автономный дизельный генератор является основной «рабочей лошадкой» там, где покаким-либо причинам недоступно централизованное электроснабжение, поэтому нет ничего удивительного в популярности дизельных электростанций. Кроме того, они обеспечивают более низкую стоимость вырабатываемой электроэнергии, что способствует быстрой окупаемости техники.
                    </p>
                    <p className='Article__container-item'>Дизельный генератор устроен на принципе двигателя внутреннего сгорания с воспламенением топлива от сжатия воздуха. Выделяющаяся при сгорания топлива энергия в дизельном двигателе производит механическую работу на валу двигателя и используется для выработки электроэнергии генератором электрического тока.</p>
                    <p className='Article__container-item'>Для работы дизельных генераторов применяются дистиллятным и остаточные виды топлива.</p>
                    <p className='Article__container-item'>К дистиллятным видам топлива относятся дизельное (по ГОСТ 305-82 марки Л - летнее, З - зимнее, А - арктическое) и газотурбинное топливо.</p>
                    <p className='Article__container-item'>К остаточным (тяжелым) топливам представляют моторное топливо для среднеоборотных дизелей (по ГОСТ 1667-68 марки ДТ и ДМ) и мазуты (по ГОСТ 10585-75 марки Ф-5 и Ф-12).</p>
                    <p className='Article__container-item'>Остаточные (тяжелые) топлива используются в дизелях, оборудованных системами топливоподготовки (сепарации и подогрева), а также специальной топливной аппаратурой (ТНВД и форсунками).</p>
                    <p className='Article__container-item'>Двутопливный двигатель (или газодизель) работает при воспламенении газовоздушной смеси от самовоспламенения запальной дозы жидкого топлива (5-12 % от цикловой порции при работе на жидком топливе). Используемый газ - попутный нефтяной, шахтный, природный без предварительной очистки.</p>
                    <p className='Article__container-item'>Область применения дизельных генераторов обширна, их используют в качестве вспомогательного, резервного или же основного источника электроснабжения на предприятиях, строй площадках, административных и медицинских учреждениях, узлах связи, гостиницах, аэропортах и т.п. в автономном режиме или же совместно с централизованными источниками электроэнергии.</p>
                    
                    <p className='Article__container__subtittle'>
                    Характеристики
                    </p>
                    <p className='Article__container-item'>Дизельный генератор, основные технические характеристики:</p>
                    </div>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                Удельный эффективный расход топлива, кг/(кВт-ч)
                            </td>
                            <td>
                                0,184-0,220
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Удельный расход масла, г/ кВт-ч
                            </td>
                            <td>
                                0,30-1,40
                            </td>
                        </tr>
                        <tr>
                            <td>
                                КПД (без утилизации теплоты)
                            </td>
                            <td>
                                0,39-0,47
                            </td>
                        </tr>
                        <tr>
                            <td>
                                КПД (с утилизацией теплоты)
                            </td>
                            <td>
                                0,70-0,80
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Мощность единичной установки, МВт
                            </td>
                            <td>
                                0,10-5,00
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Напряжение, кВ
                            </td>
                            <td>
                                0,4-13
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Диапазон рабочих режимов, % от ном. мощности
                            </td>
                            <td>
                                10-110
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Ресурс до текущего ремонта (не менее), тыс.ч.
                            </td>
                            <td>
                                10-60
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Ресурс до капитального ремонта (не менее), тыс.ч.
                            </td>
                            <td>
                                60-100
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Срок службы двигателя (не менее), тыс.ч.
                            </td>
                            <td>
                                150-300
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Затраты на ремонт, % от стоимости
                            </td>
                            <td>
                                5-20
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                Вредные выбросы: концентрация в выхлопных газах, приведенная к 5%
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                О2, мг/м3
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Nox
                            </td>
                            <td>
                                3400
                            </td>
                        </tr>
                        <tr>
                            <td>
                                CO
                            </td>
                            <td>
                                170
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Уровень шума на расстоянии 1м (не более), дБ
                            </td>
                            <td>
                                85
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Уровень шума на выхлопе (не более), дБ
                            </td>
                            <td>
                                88
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Стоимость установленной мощности, $ / кВт
                            </td>
                            <td>
                                90-200
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p className='Article__container-item'>Основными свойствами и отличительными особенностями дизельгенераторов является низкая стоимость получаемой электроэнергии, быстрая окупаемость, большой моторный ресурс и долговечность.</p>
                    <p className='Article__container-item'>Как правило, покупка или аренда электростанций является необходимой, когда необходимо решить ряд следующих задач:</p>
                    <ul className='Article__container-list'>
                        <li className='Article__container-list-item'>резервирование мощностей для работы при отключении центральных сетей (аварийный режим);</li>
                        <li className='Article__container-list-item'>ограниченные возможности централизованных источников электроэнергии и тепла при расширении мощностей (вспомогательный режим работы параллельно с центральными сетями);</li>
                        <li className='Article__container-list-item'>высокие затраты на подвод электроэнергии и тепла (автономный режим);
                        низкая себестоимость топлива для нефтедобывающих компаний и возможность реализации электроэнергии и тепла;</li>
                        <li className='Article__container-list-item'>возможность снижения зависимости от роста тарифов на электроэнергию и тепло.</li>
                    </ul>
                    <p className='Article__container-item'>Дизельные электростанции разделяют на три группы:</p>
                    <ul className='Article__container-list'>
                        <li className='Article__container-list-item'><span>1)</span>  По способу защиты от погоды: кузовные, контейнерного, капотного и бескапотного исполнения</li>
                        <li className='Article__container-list-item'><span>2)</span>  По способу подвижности: стационарные и передвижные</li>
                        <li className='Article__container-list-item'><span>3)</span>  По способу перемещения: на прицепе, полуприцепе, автомобиле, раме-салазках, блочно-транспортабельные.</li>
                    </ul>
                </div>
            </>
        );
    }
}
 
export default Article3;