import React from 'react';
class Aboutus extends React.Component{
    render(){
        return(
            <div className='aboutus__container'>
                <div className="aboutus__tittle">
                    «Машины и механизмы» предлагает в аренду дизельные генераторы, дизельные и электрические компрессоры и мини-краны на срок от 5 суток. У нас всегда выгодные расценки и полная техническая поддержка.
                </div>
                <div className="aboutus__list">
                    <div className='aboutus__list-item'>
                        <div className='aboutus__list-item-number'>01</div>
                        <div className='aboutus__list-item-text'>Широкий выбор оборудования от японского производителя, отличающегося по характеристикам</div>
                    </div>
                    <div className='aboutus__list-item'>
                        <div className='aboutus__list-item-number'>02</div>
                        <div className='aboutus__list-item-text'>
                            Всегда своевременная доставка на ваш объект
                        </div>
                    </div>
                    <div className='aboutus__list-item'>
                        <div className='aboutus__list-item-number'>03</div>
                        <div className='aboutus__list-item-text'>
                            Быстрое подключение, настройка и регулировка для работы
                        </div>
                    </div>
                    <div className='aboutus__list-item'>
                        <div className='aboutus__list-item-number'>04</div>
                        <div className='aboutus__list-item-text'>Поставки горючего</div>
                    </div>
                </div>

                <div className="aboutus__text">
                    <div className="aboutus__text-st">
                        Мы комплексно подходим к каждой поставленной задаче и берем на себя все заботы о надлежащем обслуживании. Клиенту доставляется горючее подходящего типа, поэтому ему не придется искать топливо самостоятельно. При необходимости мы будем подвозить горючее, и проводить требуемые настройки.
                    </div> 
                    <div className="aboutus__text-nd">
                        У нас возможно взять оборудование с попутным заказом услуг профессионального оператора, который будет осуществлять контроль работы генерирующих установок на вашем объекте. Это исключит любые задержки. Услуги квалифицированного персонала особенно важны, есть осуществляется работа с мощными установками, способными генерировать сотни кВт/час.
                    </div>               
                </div>

            </div>
        )
    }
}
export default Aboutus