import React from 'react';
import Slider from "react-slick";

class Feedbackmini extends React.Component{
    render(){
        const settings = {
            className: "center",
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
            swipeToSlide: true,
            responsive: [
            
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ],
            afterChange: function(index) {
              console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
              );
            }
          };
        return(
            <div className='FM__wrapper'>
                <div className='FM__tittle'>ОТЗЫВЫ</div>
                <Slider {...settings} className='Slider2'>
                    <div className='FM__item'>
                        <div className='FM__item-tittle'>Анатолий</div>
                        <div className='FM__item-text'>Здравствуйте! Хочу поблагодарить вашего менеджера за помощь в аренде компрессора. Сам не мог определить какой мне нужен. Менеджер по моим параметрам подобрал подходящий. Спасибо, продолжайте в том же духе.</div>
                    </div>

                    <div className='FM__item'>
                        <div className='FM__item-tittle'>Алексей</div>
                        <div className='FM__item-text'>Заказал аренду дизельного генератора на месяц. Все быстро оформили, привезли, обслужили. После срока аккуратно все собрали и увезли. Могу порекомендовать.</div>
                    </div>

                    <div className='FM__item'>
                        <div className='FM__item-tittle'>Олег</div>
                        <div className='FM__item-text'>Брал с семьей для строительных работ на даче в аренду генератор. Доставили в срок, установили, наладили работу, проконсультировали как пользоваться. Мы остались довольны – проблем не было, хотя подключали много разной техники. Всем советую.</div>
                    </div>

                    <div className='FM__item'>
                        <div className='FM__item-tittle'>Игорь</div>
                        <div className='FM__item-text'>Потребовалось арендовать дизельный компрессор на пару суток для выездных работ. Обратились через сайт. Обслуживание высокого уровня. Технику привезли утром, научили правильно пользоваться. Стоимость аренды доступная.</div>
                    </div>

                    <div className='FM__item'>
                        <div className='FM__item-tittle'>Вадим</div>
                        <div className='FM__item-text'>Арендуем самоходные подъемники регулярно и на долгий срок, поэтому иногда происходят мелкие поломки. Но техподдержка мгновенно реагирует и мастера приезжают в тот же день все наладить. Советую эту компанию.</div>
                    </div>
                </Slider>   
            </div>

        )
    }
}
export default Feedbackmini