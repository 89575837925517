import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article5 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Аренда электростанций с воздушным охлаждением, рекомендации по эксплуатации</div>
                    <div className='Articles__container1'>
                        <p className="Article__container-item">
                            Аренда дизельных электростанций накладывает ряд обязательств, в их числе - необходимость внимательно следить за его техническим состоянием и соблюдать общие рекомендации по эксплуатации.
                        </p>
                        <p className="Article__container-item">
                            Аренда дизельных электростанций накладывает ряд обязательств, в их числе - необходимость внимательно следить за его техническим состоянием и соблюдать общие рекомендации по эксплуатации.
                        </p>
                        <p className="Article__container-list">
                            Так оптимальным режимом работы дизельной электростанции является мощность нагрузки в 30-100% от заявленной номинальной, перегрузки при этом недопустимы.
                        </p>
                        <p className="Article__container-list">
                            Не рекомендуется продолжительное время держать генератор на низкой нагрузке или запущенном в холостую
                        </p>
                        <p className="Article__container-list">
                            Длительность эксплуатации дизельного генератора с воздушным охлаждением должна составлять в среднем от двух до четырёх часов, в зависимости от конкретной модели и условий эксплуатации.
                        </p>
                        <p className="Article__container-list">
                            Время работы при максимальной нагрузке не должно превышать двух часов.
                        </p>
                        <p className="Article__container-list">
                            Нормальным периодом работы генератора является время полной выработки одного топливного бака, после чего необходимо сделать перерыв.
                        </p>
                        <p className="Article__container-list">
                            При использовании трёхфазного дизельного генератора необходимо следить за равномерность распределения нагрузки по фазам, «перекос» не должен состовлять более 25 процентов.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article5;