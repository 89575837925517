import React from 'react';
import Slider from "react-slick";
import Partner1 from "../img/partners1.png"
import Partner2 from "../img/partners2.png"
import Partner3 from "../img/partners3.png"
import Partner4 from "../img/partners4.png"
import Partner5 from "../img/partners5.png"
import Partner6 from "../img/partners6.png"
import Partner7 from "../img/partners7.png"
class Partnerslider extends React.Component {
    
    render() { 
        const settings = {
            className: "center",
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 5,
            swipeToSlide: true,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                  }
                },
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      initialSlide: 3
                    }
                  },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 450,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ],  
            afterChange: function(index) {
              console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
              );
            }
          };
        return (
            <div className='partnerslider__container'>
                <div className='partnersslider__tittle'>
                    Наши Партнеры
                </div>
                <Slider {...settings} className='Slider3'>
                    <div className='partnerslider__item'>
                        <img src={Partner1} alt='мосметрострой'></img>
                    </div>

                    <div className='partnerslider__item'>
                        <img src={Partner2} alt='главное управление обустройства войск'></img>
                    </div>

                    <div className='partnerslider__item'>
                        <img src={Partner3} alt='ржд'></img>
                    </div>

                    <div className='partnerslider__item'>
                        <img src={Partner4} alt='росгеология'></img>
                    </div>
                          
                    <div className='partnerslider__item'>
                        <img src={Partner5} alt='бикор бмп'></img>
                    </div>

                    <div className='partnerslider__item'>
                        <img src={Partner6} alt='ао урст'></img>
                    </div>

                    <div className='partnerslider__item'>
                        <img src={Partner7} alt='брусника'></img>
                    </div>
                    
                    
                    
                </Slider>
            </div>
        );
    }
}
 
export default Partnerslider;