import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article15 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Выбор генератора</div>
                    <div className='Articles__container1'>
                        <p className='Article__container-item'>Аренда генератора — занятие не простое и гораздо более серьёзное нежели покупка, например, канцелярских принадлежностей. Для того чтобы правильно выбрать нужный генератор, который бы полностью соответствовал вашим требования, важно учесть довольно много различных факторов и характеристик, которые определяют надёжность и долговечность электростанции, а так же удобство пользования им.</p>
                        <p className='Article__container__subtitle'>Мощность генератора</p>
                        <p className='Article__container-item'>Аренда дизельных генераторов — это основное направление деятельности нашей компании, поэтому мы в состоянии предложить на ваш выбор достаточное количество различных по мощности и другим параметрам электростанций, а так же помочь в выборе конкретной модели.</p>
                        <p className='Article__container-item'>В первую очередь, озаботившись вопросом энергоснабжения, необходимо чётко поставить вопрос касательно целей использования генератора — от этого зависит и выбор всех остальных параметров.</p>
                        <p className='Article__container-item'>Так, например, если электричество необходимо вам для подключения всевозможных электроинструментов, то аренда электростанции с небольшой выходной мощностью — самое верное решение, и не нужно будет переплачивать за «лишнюю» не израсходованную мощность. В тоже время, для организации энергоснабжения загородного дома или предприятия потребуется уже гораздо более мощная установка.</p>
                        <p className='Article__container-item'>Вы можете сами определить необходимую вам мощность или обратится за помощью к специалистам нашей компании — в любом случае, это не самая сложная задача и кажется таковой только на первый взгляд. </p>
                        <p className='Article__container-item'>Для подсчёта этого показателя, достаточно сложить вместе мощности всех электрических приборов, которые вы планируете запитать от генератора, плюс прибавить к получившейся сумме 10-20% в качества запаса, чтобы в критический момент не было перегрузки. Кроме того, приборы имеющие в своём строении электромоторы (например — обычный холодильник) требуют при запуске в несколько раз большее количество тока, нежели при дальнейшей работе, так что этот вопрос тоже необходимо учесть. Именно поэтому, аренда электростанции с чуть более высокими чем необходимо на первый взгляд показателями — лучший вариант, который не подведёт вас даже в сложной ситуации.</p>
                        <p className='Article__container__subtitle'>Тип двигателя</p>
                        <p className='Article__container-item'>Есть два вида двигателей, которые повсеместно используются при производстве генераторов, это дизельный и бензиновый, каждый из которых имеют свои преимущества и недостатки, о которых вам необходимо знать, если вас интересует аренда генераторов.</p>
                        <p className='Article__container-item'>Если смотреть поверхностно, то можно просто сказать о том, что бензиновый двигатель стоит значительно дешевле чем дизельный, однако последний отличается гораздо большей надёжностью и экономичностью. Но это, безусловно, лишь верхушка айсберга.</p>
                        <p className='Article__container-item'>Бензиновый двигатель идеально подходит для непостоянного использования, например в случае временного отключения электричества на даче, именно поэтому аренда генераторов такого типа не самая популярная услуга — гораздо логичнее будет такой генератор приобрести, благо его стоимость не вызывает тревожных мыслей. Правда не стоит забывать о том, что рассчитывать на такой генератор стоит лишь в том случае, если вам требуется небольшая мощность, т.к. даже самый лучший бензиновый генератор не выдаст вам более 15 кВА, поэтому не стоит даже задумываться, чтобы в случае отключения централизованного энергоснабжения обеспечить электричеством весь дом — для этих целей необходим стационарный мощный дизель.</p>
                        <p className='Article__container-item'>Аренда дизельгенератора, это уже совсем другой уровень. Такая электростанция позволит вам решить довольно большой спектр различных задач. В частности — обеспечение электроэнергией загородного дома (даже на постоянной, а не временной основе!), или же использование его на строительных площадках, производстве или в любых других отраслях.</p>
                        <p className='Article__container-item'>Наша компания предлагает вам аренду генераторов японского производства с жидкостной системой охлаждения. Вы можете выбрать низко или высокооборотный двигатель.</p>
                        <p className='Article__container__subtitle'>Дополнительные опции</p>
                        <p className='Article__container-item'>Аренда дизельных генераторов в нашей компании позволяет вам так же выбрать ряд дополнительных опций, которые могут оказаться в различных ситуациях довольно полезными.</p>
                        <p className='Article__container-item'>К таким опциям можно отнести возможность автоматического запуска или дистанционного управления генератором, увеличенный топливный бак, шумоизоляционный кожух или дополнительный глушитель. Кроме того, по вашему желанию мы можем предоставить вам в аренду генератор с оператором, который будет постоянно следить за работой установки и заниматься её полным техническим обслуживанием.</p>
                        <p className='Article__container-item'>Обращайтесь к нам и мы обязательно поможем вам выбрать генератор и дополнительные опции исходя из ваших потребностей и финансовых возможностей.</p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article15;