import React, {  useState } from 'react';
import {Link} from "react-router-dom";
import Popup from './popup1';
const Mobilemenu=({active, setActive})=> {
    const [popupActive, setPopUpActive] = useState(false)
    const [menuActive, setMenuActive] = useState(true)
    const handleButtonClick = () => {
        window.scrollTo(0, 0);
        
    }
    return ( 
        <div className={active ? "mobilemenu__wrapper active":"mobilemenu__wrapper "}>
            <nav className='mobilemenu__nav'>
                <div className='mobilemenu__catalog'>
                <div className="catalogmenu__container">
                    <ul className="catalogmenu__list" >
                        
                        <button className="catalogmenu__list-item" onClick={ () => {setActive(false) } }><Link onClick={handleButtonClick} to='/elek-kompressor' className="catalogmenu__list-item-link" >Электрические компрессоры</Link></button>
                        <button className="catalogmenu__list-item" onClick={ () => {setActive(false)} }><Link onClick={handleButtonClick} to='/generator' className="catalogmenu__list-item-link">Генераторы</Link></button>
                        <button className="catalogmenu__list-item" onClick={ () => {setActive(false)} }><Link onClick={handleButtonClick} to='/disel-kompressor' className="catalogmenu__list-item-link">Дизельные Компрессоры</Link></button>
                        <button className="catalogmenu__list-item" onClick={ () => {setActive(false)} }><Link onClick={handleButtonClick} to='/manipulator' className="catalogmenu__list-item-link">Манипуляторы</Link></button>
                        
                    </ul>
                </div>
                </div>
                <div className='mobilemenu__links'></div>
            </nav>
            <div className='mobilemenu__adress'>
            <div className="contactsmenu__links">
                        <Link onClick={ () => {setActive(false) } } to='/about' className="contactsmenu__links-item">О компании</Link>
                        <Link onClick={ () => {setActive(false) } } to="/articles" className="contactsmenu__links-item">Статьи</Link>
                        <Link onClick={ () => {setActive(false) } } to='/contacts' className="contactsmenu__links-item">Контакты</Link>
                    </div>
            </div>
            <div className='mobilemenu__adresscontainer'>
                <div className='mobilemenu__adresscontainer-item'>121609, Москва, ул. Осенняя, д. 17/2, пом.1, ком.30,31</div>
                <div className='mobilemenu__adresscontainer-item'><a href='+79857737177'>+7 (985) 773-71-77</a> (с 9:00 до 19:00)</div>
            </div>

            <button className='mobilemenu__button' onClick={()=>setPopUpActive(true)}>Заказать звонок  </button>
            <Popup  active={popupActive} setActive={setPopUpActive} />
        </div>
     );
}

export default Mobilemenu;