import React, { useState } from 'react';
import logo2 from "../img/footer-logo.png"
import{Link} from "react-router-dom";
import Popup from "./popup1";

const Contactsmini =()=>{

        const [popupActive, setPopUpActive] = useState(false)
        return (
            <div className='contactsmini__wrapper'>
                <div className="contactsmini__container">
                    <div className="contactsmini__name">
                        <div className="contactsmini__name-top">
                            <img src={logo2} className="contactsmini__name-logo" alt='logo'></img>
                            <div className="contactsmini__name-tittle">МАШИНЫ И МЕХАНИЗМЫ</div>
                        </div>
                        <div className="contactsmini__name-desc">Аренда и продажа оборудования</div>
                    </div>
                    <div className="contactsmini__adress">121609, Москва,<br></br> ул. Осенняя, д. 17/2,<br></br> пом.1, ком.30,31</div>
                    <div className="contactsmini__number">
                        <a className='contactsmini__number-phone' href="tel:+74957737177" >+7 (495) 773-71-77</a>
                        <a className='contactsmini__number-phone' href="tel:+79257154820">+7 (925) 715-48-20</a>
                    </div>
                    <div className="contactsmini__links">
                        <Link to='/about' className="contactsmini__links-item">О компании</Link>
                        <Link to="/articles" className="contactsmini__links-item">Статьи</Link>
                        <Link to='/contacts' className="contactsmini__links-item">Контакты / Карты</Link>

                    </div>
                </div>
                <Popup  active={popupActive} setActive={setPopUpActive} />
            </div>
        );
    }

 
export default Contactsmini;