import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article12 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Аренда дизельных электростанций</div>
                    <div className='Articles__container1'>
                        <p className='Article__container-item'>Ни для кого не секрет, что наиболее эффективным источником подачи электроэнергии в зоне с нестабильным или отсутствующим электрообеспечением являются дизельные генераторы различных типов и мощностей. Именно поэтому аренда дизельных электростанцийявляется сегодня такой востребованной услугой.</p>
                        <p className='Article__container-item'>Стоимость даже самого простого дизельного генератора довольно высока, поэтому в том случае, если необходимость в использовании установки предположительно будет возникать не часто, то аренда генератора выйдет значительно дешевле. Кроме того, в такой ситуации у вас остаётся возможность брать в аренду генератор нужной мощности под каждую конкретную ситуацию, а не покупать очень мощную установку на случай «а вдруг пригодиться».</p>
                        <p className='Article__container-item'>Аренда дизельгенератора — не простая задача, поэтому прежде всего необходимо ознакомиться с общими для всех моделей характеристиками и типами дизельных установок.</p>
                        <p className='Article__container-item'>Все электростанции состоят из непосредственно дизельного двигателя, генератора постоянного тока, а так же системы охлаждения, которая бывает воздушной или водяной. Генераторы с воздушной системой охлаждения стоят дешевле — как в случае покупки, так и аренды, однако их нельзя использовать без перерыва — это грозит перегревом системы, в то время как установки с водяной системой охлаждения могут работать 365 дней в году. Кроме того, все генераторы помещаются в шумоизолирующий кожух, который не только подавляет звук работы установки, но и выполняет защитную функцию.</p>
                        <p className='Article__container-item'>Возможна аренда электростанции стационарого или передвижного (мобильного) типа. Стационарные, как правило, более мощные и используются на различных промышленных и производственных объектах, в геолого разведывательных и прочих экспедициях, в то время как генераторы мобильного типа часто применяются при строительстве небольших объектов, ремонтными и аварийными службами, а так же в качестве основных или резервных источников питания при проведении всевозможных мероприятий.</p>
                        <p className='Article__container-item'>Необходимая вам мощность — вот главный и самый первый вопрос, который вам зададут в любой фирме, куда вы обратитесь с вопросом касательно аренды дизельгенератора. А ответ на него, то есть требуемая мощность, напрямую зависит от того фронта работ и количества и ресурсоёмкости оборудования, которое будет постоянно подключено к установке.</p>
                        <p className='Article__container__subtitle'>Дизельные электростанции в Москве</p>
                        <p className='Article__container-item'>Так аренда дизельгенератора контейнерного типа мощностью от 50 до 600 кВт позволит вам обеспечить автономное энергоснабжение достаточное для полноценной работы небольшой строительной площадки или, даже, добычи ископаемых.</p>
                        <p className='Article__container-item'>Для создания источника резервного питания в среднем по величине загородном посёлке потребуется аренда генераторов мощность не менее 50-80 кВт. Крупные потребители, вроде железнодорожных вокзалов или аэропортов вынуждены использовать более мощные источники питания, причём с обязательной возможностью мгновенного автоматического запуска при пропадании электричества в основном источнике питания, — ведь от работы всех систем на таких объектах зависят жизни тысяч людей.</p>
                        <p className='Article__container-item'>Достаточно трудно самому рассчитать мощность необходимую для обеспечения электроэнергией промышленного объекта, т.к. в зависимости от типа производства и множества других факторов эта цифра может значительно меняется, однако с этой задачей вам обязательно помогут справиться наши специалисты — ведь мы заинтересованны в создании всех условий, чтобы аренда электростанции в нашей компании стала для вас удобной.</p>
                        <p className='Article__container-item'>Если смотреть в среднем, то небольшим промышленным предприятиям обычного достаточно аренды генератора контейнерного типа и мощностью до 400 кВт, в то время как крупные собственники, в задачу которых входит создание независимого энергоснабжения для, скажем, гостиницы или торгового центра, может понадобиться выходная мощность вплоть до 5000 кВт.</p>
                        <p className='Article__container-item'>Надёжность используемого нами оборудования и комплектующих, качественное и своевременное сервисное обслуживание и высококвалифицированные кадры — вот залог успеха нашей компании, которым мы с удовольствием готовы поделиться с вами! Аренда дизельных генераторов в нашей компании поможет вам в организации производственного процесса и заставит навсегда забыть о каких бы то ни было проблемах связанных с энергоснабжением ваших объектов.</p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article12;