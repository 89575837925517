import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article14 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Аренда генераторов для бизнеса</div>
                    <div className='Articles__container1'>
                        <p className='Article__container-item'>Аренда генераторов сегодня используется в большом количестве различных сфер деятельности, в тех случаях, когда требуется бесперебойная подача энергии во время возникновения аварийной ситуации или в качестве постоянного источника питания.</p>
                        <p className='Article__container-item'>Дизельные генераторы используются во всевозможных медицинских учреждениях, сельском хозяйстве и в любой производственной сфере, а так же, в последнее время, всё чаще такая услуга как аренда генератора стала пользоваться спросом в частном секторе — многие владельцы загородных домов и коттеджей хотят обезопасить себя от проблем связанных с возможным отсутствием электроэнергии. </p>
                        <p className='Article__container-item'>В современной промышленности аренда электростанций - это просто необходимость, ведь даже краткосрочное отсутствие электроэнергии может сорвать производственный процесс и сроки выполнения заказов, а в некоторых случаях даже стать причиной техногенной катастрофы. Именно поэтому практически не возможно найти сейчас производственное помещение не оборудованное резервным источником питания вроде дизельного генератора.</p>
                        <p className='Article__container-item'>Так же, в качестве основного источника питания,аренда дизельных генераторов используется при проведении строительных работ, по крайней мере, на их начальной стадии — до тех пор, пока к строительной площадке не подведена хотя бы временная линия централизованной подачи энергии.</p>
                        <p className='Article__container-item'>Помимо этого, аренда электростанций практически повсеместно используется при проведении любых крупных мероприятий так или иначе связанных с большим скоплением людей — спортивные и культурные события, концерты, фестивали, выставки и т.п.. Любое подобное событие достаточно требовательно к энергоресурсам, т.к. используется большое количество светового и другого оборудования. Аренда генераторов в таких ситуациях может исполнять роль как и единственного и основного источника питания, так и резервного — на тот случай, чтобы мероприятие не было сорвано из-за возможных проблем с основной линией питания.</p>
                        <p className='Article__container-item'>Ещё одной причиной роста рынка услуг по аренде дизельных электростанций является довольно высокая стоимость подобного оборудования, что не всегда по карману небольшим предприятиям, особенно остро этот вопрос стоит в регионах.</p>
                        <p className='Article__container-item'>В такой ситуации — аренда дизельгенератора является наиболее разумным с точки зрения экономики выходом — за плату существенно меньшую чем стоимость электростанции, компания получает в своё распоряжение надёжную установку на длительный срок, к тому же избавляя себя от затрат на ремонт, техническое обслуживание и обучения персонала.</p>
                        <p className='Article__container-item'>По желанию клиента, мы можем предоставить в аренду генератор с оператором, в обязанности которого входит постоянный контроль за энергоустановкой, её настройка и отладка, а так же контроль за своевременной доставкой топлива. Плюс ко всему, мы осуществляем круглосуточную техническую поддержку своих клиентов и всегда готовы оперативно заменить наше оборудование, если оно по каким-то причинам вышло из строя.</p>
                        <p className='Article__container-item'>Кроме того, аренда дизельных генераторов позволяет клиенту выбрать конкретную модель, которая бы полностью удовлетворяла его требования, но которую фирма не может позволить себе приобрести на данном этапе своего развития.</p>
                        <p className='Article__container-item'>Специалисты нашей компании с удовольствием помогут вам с выбором модели, рассчитают необходимую мощность и предложат дополнительные услуги на ваш выбор. Аренда генератора в «УМ-77» - это просто, удобно и быстро!</p>
                        <p className='Article__container-item'>В ассортименте нашей компании — лучшие генераторы с дизельными двигателями японского производства, которые хорошо зарекомендовали себя на мировом рынке. Список продукции предлагаемой нами постоянно увеличивается, но одно всегда остаётся неизменным — мы предлагаем своим клиентам только самое надёжное и качественное оборудование.</p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article14;