import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen5 from '../../img/generator5.jpg'
import { Helmet } from "react-helmet";
function Generator5() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>50 кВт 65 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>дизель 80 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>10.5 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/220 V 50/60 Гц 95А</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>155 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1600 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2630 х 1000 х 1300</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>3 900 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>- с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>1 200 руб</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор DENYO DCA 75"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 50 кВт DENYO DCA 75</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>50 кВт 65 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>дизель 80 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>10.5 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/220 V 50/60 Гц 95А</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>155 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1600 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2630 х 1000 х 1300</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>3 900 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>- с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>1 200 руб</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen5} alt='Аренда дизельного генератора 50 кВт DENYO DCA 75'></img>
            </div>
            <div className='item__desk'>
                <p className='Article__container-item'>Если потребовался дизельный генератор на 50 кВт, а приобрести его нет средств, то лучшим решением является аренда Denyo DCA 75. Несомненно выгодный вариант, вне зависимости от требуемого срока.</p>
                <p className='Article__container__subtitle'>Преимущества аренды дизель генератора в 50 кВт в компании «Машины и механизмы» </p>
                <p className='Article__container-item'>Выгода. Не нужно приобретать аппарат, если он не требуется вам регулярно.</p>
                <p className='Article__container-item'>Удобство. Вы получаете качественное и полностью исправное оборудование, которое будет работать без перебоев.</p>
                <p className='Article__container-item'>Гарантия. В случае если по каким-то причинам генератор сломался, специалисты нашей фирмы заменят его на рабочий. </p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator5;