import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article6 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Как правильно выбрать дизельный компрессор?</div>
                    <div className='Articles__container1'>
                        <p className="Article__container-item">
                        Для чего нужна аренда компрессора? Это выгодно и удобно. Если Вам требуется выполнять разовые или периодические работы, требующие применения пневматического оборудования, аренда компрессора – это ваш выбор! Вам не нужно покупать дорогостоящее оборудование, заботиться об его техническом обслуживании. Если ваши потребности в мощности со временем возрастут, то можно просто обменять взятый в аренду компрессор на более мощный, не потеряв при этом деньги.
                        </p>
                        <p className="Article__container-item">
                            Винтовой дизельный компрессор - вид компрессора, в котором сжатие рабочего воздуха происходит по средствам сцепленных между собой роторов с винтовыми зубьями.
                        </p>
                        <p className="Article__container-item">
                            Для чего нужна аренда компрессора? Это выгодно и удобно. Если Вам требуется выполнять разовые или периодические работы, требующие применения пневматического оборудования, аренда компрессора – это ваш выбор! Вам не нужно покупать дорогостоящее оборудование, заботиться об его техническом обслуживании. Если ваши потребности в мощности со временем возрастут, то можно просто обменять взятый в аренду дизельный компрессор на более мощный, не потеряв при этом деньги.
                        </p>
                        <p className="Article__container-item">
                            У нас вы можете арендовать дизельные компрессоры, подающие сжатый воздух под давлением в 7 атмосфер. Такой мощности достаточно для работы как обычного пневмо-инструмента, так и для промышленных устройств. С помощью наших  дизельных компрессоров можно выполнять такие работы, как: ремонтные дорожные работы, строительство, расчистка местности, покраска, распыление и многое другое.
                        </p>
                        <p className="Article__container__subtittle">
                            Как правильно выбрать компрессор для аренды?
                        </p>
                        <p className="Article__container-item">
                            Из каких предпосылок нужно исходить, делая выбор в пользу аренды того или иного компрессора? Исходить нужно из Ваших потребностей. Звучит не очень оригинально, но верно. Никто лучше Вас не знает, для чего понадобится дизельный компрессор, поэтому первоначально нужно определиться с целью аренды. Перед первым звонком к нам, желательно точно определить, сколько будет потребителей сжатого воздуха, их рабочие параметры (давление и расход воздуха) и предполагаемые режимы работы. Рабочие характеристики пневмо-оборудования можно найти в его паспорте.
                        </p>
                        <p className="Article__container-item">
                            Также нужно учитывать, что работы с пневмо-инструментом не непрерывны, а носят временной характер. Поэтому, для выбора параметров дизельного компрессора берите средние значения. Для их расчета требуется хорошо представлять, какие работы будут проводиться, сколько нужно будет одновременно работающих инструментов, продолжительность их включения. Это все относиться в основном к тем, для кого аренда компрессора происходит впервые.
                        </p>
                        <p className="Article__container-item">
                            Если же Вы уже пользуетесь компрессором, но он вам не подходит, например, из-за роста количества используемого инструмента или увеличения потребляемой нагрузки, то Вы должны узнать технические параметры используемого компрессора и четко описать то, что Вас не удовлетворяет в его работе. Например, если работы часто приходиться прерывать из-за недостаточной подачи сжатого воздуха, то требуется оценить время, за которое давление в ресивере опускается ниже допустимого уровня.
                        </p>
                        <p className="Article__container-item">
                            Следуйте этим простым правилам, и аренда компрессора станет для вас простой задачей.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article6;