import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"

import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen12 from '../../img/generator12.jpg';
import { Helmet } from "react-helmet";
function Generator12() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>400 кВт</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>Cummins QSX15G8</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>79 л/час</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>900 л.</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>5691 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>5110x1563x2447 мм</td>
        </tr>
        </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор Cummins C500D5"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 400 кВт Cummins C500D5</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>400 кВт</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>Cummins QSX15G8</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>79 л/час</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>900 л.</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>5691 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>5110x1563x2447 мм</td>
        </tr>
        </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen12} alt='Аренда дизельного генератора 400 кВт Cummins C500D5'></img>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator12;