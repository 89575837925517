import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"

import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import man1 from '../../img/manipulatormain1.jpeg'
import { Helmet } from "react-helmet";
function Manipulator1() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Полная масса</td>
            <td>12 тонн</td>
        </tr>
                    <tr>
            <td>Мощность двигателя	</td>
            <td>177 л.с.</td>
        </tr>
                    <tr>
            <td>КПП</td>
            <td>механическая</td>
        </tr>
                    <tr>
            <td>Экологический класс	</td>
            <td>ЕВРО 5</td>
        </tr>
                    <tr>
            <td>Масса в снаряенном состоянии</td>
            <td>7 тонн</td>
        </tr>
                    <tr>
            <td>Возможная масса груза</td>
            <td>5 тонн</td>
        </tr>
                    <tr>
            <td>Манипулятор</td>
            <td>PALFINGER 13500</td>
        </tr>
                    <tr>
            <td>Грузовой момент</td>
            <td>14 тонн</td>
        </tr>
                    <tr>
            <td>Подьем рядом с платформой</td>
            <td>4 тонны</td>
        </tr>
                    <tr>
            <td>Выдвижение стрелы</td>
            <td>10,5 метров</td>
        </tr>
                    <tr>
            <td>Подьем на мах вылете</td>
            <td>1 тонна</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                                        <tr>
            <td>смена </td>
            <td>от 12 500 р</td>
        </tr>
                                                        <tr>
            <td>далее 10 км от МКАД	</td>
            <td>+60 р за 1 км</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Манипулятор MERSEDES BENZ ATEGO 1218"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>MERSEDES BENZ ATEGO 1218</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Полная масса</td>
            <td>12 тонн</td>
        </tr>
                    <tr>
            <td>Мощность двигателя	</td>
            <td>177 л.с.</td>
        </tr>
                    <tr>
            <td>КПП</td>
            <td>механическая</td>
        </tr>
                    <tr>
            <td>Экологический класс	</td>
            <td>ЕВРО 5</td>
        </tr>
                    <tr>
            <td>Масса в снаряенном состоянии</td>
            <td>7 тонн</td>
        </tr>
                    <tr>
            <td>Возможная масса груза</td>
            <td>5 тонн</td>
        </tr>
                    <tr>
            <td>Манипулятор</td>
            <td>PALFINGER 13500</td>
        </tr>
                    <tr>
            <td>Грузовой момент</td>
            <td>14 тонн</td>
        </tr>
                    <tr>
            <td>Подьем рядом с платформой</td>
            <td>4 тонны</td>
        </tr>
                    <tr>
            <td>Выдвижение стрелы</td>
            <td>10,5 метров</td>
        </tr>
                    <tr>
            <td>Подьем на мах вылете</td>
            <td>1 тонна</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>рейс</td>
            <td>7 500 р</td>
        </tr>
                                                        <tr>
            <td>смена 1+7 часов</td>
            <td>9 000 р</td>
        </tr>
                                                        <tr>
            <td>далее 10 км от МКАД	</td>
            <td>+40 р за 1 км</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image manmain' src={man1} alt='аренда MERSEDES BENZ ATEGO 1218'></img>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Manipulator1;