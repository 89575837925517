import React from "react"
import Catalog from "../components/catalog"
import HeaderSubSpace from "../components/helpcomponents/headerspace"
import profit1 from '../img/profit-icon1.png'
import profit2 from '../img/profit-icon2.png'
import profit3 from '../img/profit-icon3.png'
import profit4 from '../img/profit-icon4.png'
import airmanbg from '../img/airman-bg.png'
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";
class About extends React.Component{
    handleButtonClick = () => {
            window.scrollTo(0, 0);
        }
    render(){
        
        return(
            <> 
            <Helmet title="Машины и Механизмы - о компании"></Helmet>
                <HeaderSubSpace/>
                <Catalog/>
                <div className="about__container">
                        <div className="about__tittle">О компании</div>


                        <div className='aboutus__container'>
                    <div className="aboutus__tittle">
                        «Машины и механизмы» предлагает в аренду дизельные компрессоры, дизельные генераторы, и вышки на срок от 5 суток. У нас всегда выгодные расценки и полная техническая поддержка.
                    </div>
                    <div className="aboutus__list">
                        <div className='aboutus__list-item'>
                            <div className='aboutus__list-item-number'>01</div>
                            <div className='aboutus__list-item-text'>Широкий выбор оборудования от японского производителя, отличающегося по характеристикам</div>
                        </div>
                        <div className='aboutus__list-item'>
                            <div className='aboutus__list-item-number'>02</div>
                            <div className='aboutus__list-item-text'>
                                Всегда своевременная доставка на ваш объект
                            </div>
                        </div>
                        <div className='aboutus__list-item'>
                            <div className='aboutus__list-item-number'>03</div>
                            <div className='aboutus__list-item-text'>
                                Быстрое подключение, настройка и регулировка для работы
                            </div>
                        </div>
                        <div className='aboutus__list-item'>
                            <div className='aboutus__list-item-number'>04</div>
                            <div className='aboutus__list-item-text'>Поставки горючего</div>
                        </div>
                    </div>

                    <div className="aboutus__text">
                        <div className="aboutus__text-st">
                            Мы комплексно подходим к каждой поставленной задаче и берем на себя все заботы о надлежащем обслуживании. Клиенту доставляется горючее подходящего типа, поэтому ему не придется искать топливо самостоятельно. При необходимости мы будем подвозить горючее, и проводить требуемые настройки.
                        </div> 
                        <div className="aboutus__text-nd">
                            У нас возможно взять оборудование с попутным заказом услуг профессионального оператора, который будет осуществлять контроль работы генерирующих установок на вашем объекте. Это исключит любые задержки. Услуги квалифицированного персонала особенно важны, есть осуществляется работа с мощными установками, способными генерировать сотни кВт/час.
                        </div>               
                    </div>
                </div>


                    <div className='shortabout__wrapper'>
                        <div className='shortabout__container'>
                            <div className='section__shortabout'>
                                <img src={airmanbg} className='shortabout-image' alt="airman"></img>
                                <ul className='profit__list'>
                                    <li className='profit__list-item'>
                                        <img src={profit1} className='profit__list-item-image' alt="надежность"></img>
                                        Надежность от японского производителя с 80-летней историей
                                    </li>
                                    <li className='profit__list-item'>
                                        <img src={profit2} className='profit__list-item-image' alt="соответсвие"></img>
                                        Соответствие европейским стандартам качества, безопасности и экологичности
                                    </li>
                                    <li className='profit__list-item'>
                                        <img src={profit3} className='profit__list-item-image' alt="выбор"></img>
                                        Более 36 видов оборудования под любые задачи
                                    </li>
                                    <li className='profit__list-item'>
                                        <img src={profit4} className='profit__list-item-image' alt="аренда"></img>
                                        Возможность аренды как с нашим обслуживанием, так и без него
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="about__wrapper">
                        <div className="about__list">
                            <div className="about__list-item">
                                <p className="about__list-item-tittle">Аренда вышек</p>
                                <p className="about__list-item-text">Строительная вышка – это передвижная сборно-разборная металлоконструкция башенного типа из нескольких одинаковых промежуточных секций, размещенных одна на другой, оснащенная колесами, которые позволяют перекатывать башню с места на место без особых усилий.
                                <br/><br/>
                                Передвижное основание оборудовано настилом для размещения балласта, придающего туре устойчивость. Строительные башни высотой более 8 м комплектуются дополнительными стабилизаторами – специальными упорами – и домкратами. Максимальная высота вышки может составлять 21 м.</p>
                                <Link to='/nozhnichniy_podyemnik' className="about__list-item-button" onClick={this.handleButtonClick}> Перейти в каталог</Link>
                            </div>
                            <div className="about__list-item">
                                <p className="about__list-item-tittle">Аренда дизельных генераторов</p>
                                <p className="about__list-item-text">Если Вас интересует аренда генератора без обслуживания, то мы предоставляем с генератором сертифицированную емкость для хранения дизельного топлива, подключаем ее к генератору для бесперебойной работы, завозим по необходимости топливо и систематически проводим контроль за работой станции. Вы только глушите и заводите двигатель по мере необходимости,
                                <br/><br/>
                                Если Вас интересует аренда дизельгенератора с обслуживанием то, к выше перечисленному, добавляется оператор, который присутствует на объекте и контролирует работу станции (второй вариант обычно применяется, когда происходит аренда электростанций мощностью свыше 200 кВт).</p>
                                <Link to='/generator' className="about__list-item-button" onClick={this.handleButtonClick}>Перейти в каталог</Link>
                            </div>
                            <div className="about__list-item">
                                <p className="about__list-item-tittle">Аренда дизельного компрессора</p>
                                <p className="about__list-item-text">Дизельный компрессор имеет винтовой элемент, который не требует специального технического обслуживания во время всего срока эксплуатации компрессора, а автоматическая регулировка мощности позволит подачу потребителю оптимального количества воздуха.
                                <br/><br/>
                                Модельный ряд представлен тремя моделями, поэтому вы можете подобрать наиболее выгодный вариант дизельного компрессора, который подходит именно вашим запросам.</p>
                                <Link to='/kompressor' className="about__list-item-button" onClick={this.handleButtonClick}>Перейти в каталог</Link>
                            </div>
                            <div className="about__list-item">
                                <p className="about__list-item-tittle">Аренда мини-крана</p>
                                <p className="about__list-item-text">Используя наш мини-кран манипулятор, в первую очередь, вы получаете повышенную надежность в работе, а так же за счет использования меньшего количества узлов и шарниров меньшую стоимость технического обслуживания оборудования. Отличительными особенностями данного мини-крана является возможность использования для подъема груза, находящегося за препятствием, такими как стены, заборы, ограждения и другие объекты. Возможность работы с грузами, которые расположены ниже уровня отметки земли до 22 метров, позволит Вам использовать мини-кран при монтаже коммуникаций зданий.
                                <br/><br/>
                                Благодаря точности и плавности работы механизмов груз опускается на землю плавно, что практически исключает возможность его удара или повреждения, а так же имеется возможность поднимать его строго вертикально, что выгодно отличает его от манипулятора, который использует жесткую конструкцию. Тросовая подвеска крюка обеспечит точное перемещение груза, в отличие от машины без этой опции, поэтому груз опускается в назначенное место с максимальной точностью. Аренда мини-крана поможет существенно сэкономить затраты на тяжелую технику за счет своей маневренности и возможности подъехать близко к грузу, монтировать этажные перекрытия и лестничные пролеты даже изнутри здания.</p>
                                <Link to='/manipulator' className="about__list-item-button" onClick={this.handleButtonClick}>Перейти в каталог</Link>
                            </div>
                        </div>
                    </div>
                    
                </div>     
            </>
           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        )
    }
}

export default About