import React from "react"
import Work1 from "../img/work1.png"
import Work2 from "../img/work2.png"
import Work3 from "../img/work3.png"
import Work4 from "../img/work4.png"
class Howwork extends React.Component{
    render(){
        return(
            <div className="workinfo__wrapper">
                <div className="workinfo__container">
                    <div className="workinfo__tittle">
                        Как мы работаем
                    </div>
                    <div className="workinfo__block">
                        <div className="workinfo__list">
                            <div className="workinfo__list-item">
                                <div className="workinfo__list-item-pic">
                                    <img src={Work1} alt=""></img>
                                </div>
                                <div className="workinfo__list-item-tittle">
                                    Подбор оборудования
                                </div>
                                <div className="workinfo__list-item-text">
                                    Вы связываетесь с менеджером по телефону или отправляете запрос
                                </div>
                            </div>                        
                            <div className="workinfo__list-item">
                                <div className="workinfo__list-item-pic">
                                    <img src={Work2} alt=""></img>
                                </div>
                                <div className="workinfo__list-item-tittle">
                                        Пакет документов
                                </div>
                                <div className="workinfo__list-item-text">
                                        Мы заключаем с вами договор и выставляем счет на оплату
                                </div>                        
                            </div>
                            <div className="workinfo__list-item">
                                <div className="workinfo__list-item-pic">
                                    <img src={Work3} alt=""></img>
                                </div>
                                <div className="workinfo__list-item-tittle">
                                    Оплата оборудования
                                </div>
                                <div className="workinfo__list-item-text">
                                    Условия при заключении договора
                                </div>                          
                            </div>    
                                            
                            <div className="workinfo__list-item">
                                <div className="workinfo__list-item-pic">
                                    <img src={Work4} alt=""></img>
                                </div>
                                <div className="workinfo__list-item-tittle">
                                    Доставка оборудования
                                </div>
                                <div className="workinfo__list-item-text">
                                    Доставляем оборудование на ваш объект уже через 2 часа после оплаты
                                </div>   
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            
        )   
    }

}
export default Howwork
