import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen8 from '../../img/generator8.jpg';
import { Helmet } from "react-helmet";
function Generator8() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>155 кВт 220 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо дизель 246 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>32 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/220 50/60 230А</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>370 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>3.900 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>3840 х 1290 х 1740</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>6 700 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>8 300 руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>2 500 руб</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор AIRMAN SDG 220 S"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 150 кВт AIRMAN SDG 220 S</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>155 кВт 220 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо дизель 246 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>32 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/220 50/60 230А</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>370 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>3.900 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>3840 х 1290 х 1740</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>6 700 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>8 300 руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>2 500 руб</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen8} alt='Аренда дизельного генератора 150 кВт AIRMAN SDG 220 S'></img>
            </div>
            <div className='item__desk'>
                <p className='Article__container-item'>Аренда генератора в компании «Машины и механизмы» является правильным и рациональным решением, которое будет выигрышным для вас с финансовой стороны. Онопоспособствует выполнению работ в нужные сроки без необходимости покупки оборудования.</p>
                <p className='Article__container__subtitle'>Выгодная аренда генератора 150 кВт</p>
                <p className='Article__container-item'>Основные преимущества:</p>
                <p className='Article__container-list'>Не нужно тратить денежные средства на приобретение дорогой дизельной электростанции;</p>
                <p className='Article__container-list'>Все вопросы по ремонту, замене запчастей, замене топлива и другим техническим задачам решает компания;</p>
                <p className='Article__container-list'>Аренда дизель генератора 150 квт оформляется на любой срок, от 1 часа до 12 месяцев;</p>
                <p className='Article__container-list'>На балансе фирмы отсутствуют дорогие электростанции;</p>
                <p className='Article__container-list'>Получение экономии на сотруднике, который обслуживает данное оборудование.</p>
                <p className='Article__container-item'>Все оборудование, которое представлено на сайте компании содержится в хорошем состоянии и всегда есть в наличии для скорейшего оформления аренды.</p>
                <p className='Article__container-item'>С помощью дизельных генераторов от компании «Машины и механизмы» можно с лёгкостью обеспечить основной и запасной источник питания на различных объектах, где нет централизованного энергоснабжения или часто происходят перебои. Арендуемые генераторы соответствуют всем экологическим нормам.</p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator8;