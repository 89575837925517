import React from "react";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import Generator1 from "../../img/generator1.jpg"

import Generator3 from "../../img/generator3.png"
import Generator4 from "../../img/generator4.jpg"

import Generator6 from "../../img/generator6.jpg"
import Generator7 from "../../img/generator7.jpg"
import Generator9 from "../../img/generator9.jpg"
import Generator10 from "../../img/generator10.jpg"
import Popup from "../popup1";
import { useState } from "react";

const SubGeneratori = () => {
    const handleButtonClick = () => {
        window.scrollTo(0, 0);;
    }
        
        const [popupActive, setPopUpActive] = useState(false)

        const settings = {
            className: "center",
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 4,
            swipeToSlide: true,
            responsive: [
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ],            
            afterChange: function(index) {
              console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
              );
            }
            
          };

        
        return(
                <div className="gS__wrapper">
                    <Slider {...settings} className="Slider1">
                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                <Link to='/Generator1' onClick={handleButtonClick} className="gs__item__top-link">
                                    <img src={Generator1}  className="gs__item__top-pic" alt="аренда Airman SDG 25 S"></img>
                                </Link>
                               
                            </div> 
                            <div className="gS__item__bottom">
                                <Link to='/Generator1' className="gs__item__bottom-tittle"> <p onClick={handleButtonClick}>Дизельный генератор <br/> Airman SDG 25 S</p> </Link>
                                <div className="gs__item__bottom-power">16 кВт 20 kVa</div>
                                <div className="gs__item__bottom-power">двигатель 26 л.с.</div>
                                
                            
                            
                            
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                <Link to='/Generator3' onClick={handleButtonClick} className="gs__item__top-link">
                                    <img src={Generator3} className="gs__item__top-pic denyodca45" alt="аренда Denyo DCA 45 S"></img>
                                </Link>
                            </div> 
                            <div className="gS__item__bottom">
                                <Link to='/Generator3' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор <br/>Denyo DCA 45 S</p> </Link>
                                <div className="gs__item__bottom-power">30 кВт 38 kVa</div>
                                <div className="gs__item__bottom-power">двигатель 46 л.с.</div>
                                
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/Generator4' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Generator4} className="gs__item__top-pic" alt="аренда Airman SDG 60 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Generator4' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Airman SDG 60 S</p></Link>
                                    <div className="gs__item__bottom-power">40 кВт 50 kVa</div>
                                    <div className="gs__item__bottom-power">двигатель 66 л.с.</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top" >
                                    <Link to='/Generator6' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Generator6} className="gs__item__top-pic" alt="аренда Airman SDG 100 S"></img>
                                    </Link>
                            </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Generator6' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Airman SDG 100 S</p></Link>
                                    <div className="gs__item__bottom-power">64 кВт 80 kVa</div>
                                    <div className="gs__item__bottom-power">двигатель 100 л.с.</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/Generator7' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Generator7} className="gs__item__top-pic" alt="аренда Airman SDG 150 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Generator7' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Airman SDG 150 S</p></Link>
                                    <div className="gs__item__bottom-power">100 кВт 125kVa</div>
                                    <div className="gs__item__bottom-power">двигатель 160 л.с.</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/Generator9'  className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Generator9} className="gs__item__top-pic" alt="аренда Airman SDG 300 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Generator9' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор <br/>Airman SDG 300 S</p></Link>
                                    <div className="gs__item__bottom-power">216 кВт 270kVa</div>
                                    <div className="gs__item__bottom-power">двигатель 330 л.с.</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/Generator10' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Generator10} className="gs__item__top-pic" alt="аренда WILSON 380 E"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Generator10' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор <br/>WILSON 380 E</p></Link>
                                    <div className="gs__item__bottom-power">216 кВт 270kVa</div>
                                    <div className="gs__item__bottom-power">двигатель 400 л.с.</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>
                    
                    </Slider>
                    <Popup  active={popupActive} setActive={setPopUpActive} />
                    <div>
                    <Link to='/generator' className="gs__bottom__link" onClick={handleButtonClick}>
                        Смотреть все
                    </Link>
                </div>
                </div>               

        )
    }
export default SubGeneratori

