import React from 'react';
class HelpP extends React.Component {
    state = {  } 
    render() { 
        return (<p >Мы осуществляем доставку и вывоз с объекта (стоимость от веса выбранной ТЕХНИКИ, от 12.500 рублей туда и обратно + 60 рублей за каждый километр от МКАД . )<br/>

        Краткое обучение персонала на объекте, помощь в начале работы с нашей ТЕХНИКОЙ входит в доставку.<br/>
        
        Оплата по безналичному расчету, с НДС и без после заключения договора</p>);
    }
}
 
export default HelpP;