import React from "react"
import Header from "./components/Header"
import Home from "./pages/Home"
import { BrowserRouter } from "react-router-dom"
import{ Routes, Route} from "react-router-dom";
import About from "./pages/aboutPage"
import Article from "./pages/articlesPage"
import Contact from "./pages/contactsPage"
import MainKompressori from "./pages/kompressoriMain"
import MainGeneratory from "./pages/generatoryMain"
import MainManipulatori from "./pages/manipulatoriMain"
import MainPodemniki from "./pages/podemnikiMain"
import MainSellTech from "./pages/selltechMain"
import Footer from "./components/footer"
import Contactsmini from "./components/contactsmini"
import PrivacyPolitic from "./pages/privacypolitic"
import QuestionsAnswers from "./pages/QandA"

import Article1 from "./pages/subsarticles/Article1"
import Article2 from "./pages/subsarticles/Article2"
import Article3 from "./pages/subsarticles/Article3"
import Article4 from "./pages/subsarticles/Article4"
import Article5 from "./pages/subsarticles/Article5"
import Article6 from "./pages/subsarticles/Article6"
import Article7 from "./pages/subsarticles/Article7"
import Article8 from "./pages/subsarticles/Article8"
import Article9 from "./pages/subsarticles/Article9"
import Article10 from "./pages/subsarticles/Article10"
import Article11 from "./pages/subsarticles/Article11"
import Article12 from "./pages/subsarticles/Article12"
import Article13 from "./pages/subsarticles/Article13"
import Article14 from "./pages/subsarticles/Article14"
import Article15 from "./pages/subsarticles/Article15"
import Article16 from "./pages/subsarticles/Article16"

import Generator1 from "./pages/generatory/generator1"
import Generator2 from "./pages/generatory/generator2"
import Generator3 from "./pages/generatory/generator3"
import Generator4 from "./pages/generatory/generator4"
import Generator5 from "./pages/generatory/generator5"
import Generator6 from "./pages/generatory/generator6"
import Generator7 from "./pages/generatory/generator7"
import Generator8 from "./pages/generatory/generator8"
import Generator9 from "./pages/generatory/generator9"
import Generator10 from "./pages/generatory/generator10"
import Generator11 from "./pages/generatory/generator11"
import Generator12 from "./pages/generatory/generator12"
import Kompressor1 from "./pages/kompressory/kompressor1"
import Kompressor2 from "./pages/kompressory/kompressor2"
import Kompressor3 from "./pages/kompressory/kompressor3"
import Kompressor4 from "./pages/kompressory/kompressor4"
import Kompressor5 from "./pages/kompressory/kompressor5"
import Kompressor6 from "./pages/kompressory/kompressor6"
import Kompressor7 from "./pages/kompressory/kompressor7"
import Kompressor8 from "./pages/kompressory/kompressor8"
import Podemnik1 from "./pages/podemniky/podemnik1"
import Podemnik2 from "./pages/podemniky/podemnik2"
import Podemnik3 from "./pages/podemniky/podemnik3"


import Manipulator1 from "./pages/manipilatory/manipulator1"
import Manipulator2 from "./pages/manipilatory/manipulator2"
import Selltech1 from "./pages/selltech/selltech1"

import Helmet from "react-helmet"
function App() {
  
  
    return(
      <BrowserRouter>
      <Helmet title="Аренда генераторов и компрессоров">
                <title>Аренда генераторов и компрессоров</title>
                <meta name="description" content="«Машины и механизмы» предлагает в аренду дизельные компрессоры, дизельные генераторы, и вышки на срок от 5 суток. У нас всегда выгодные расценки и полная техническая поддержка."/>
                <html lang="ru"></html>
                <meta name="yandex-verification" content="1dc2f6ef9fd91908" />
      </Helmet>
        <div>
          <Header/>
          <Routes>
            
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/articles" element={<Article/>}/>

            {/* статьи */}
            <Route path="/*" element={<Home/>}/>
            <Route path="/Article1" element={<Article1/>}/>
            <Route path="/Article2" element={<Article2/>}/>
            <Route path="/Article3" element={<Article3/>}/>
            <Route path="/Article4" element={<Article4/>}/>
            <Route path="/Article5" element={<Article5/>}/>
            <Route path="/Article6" element={<Article6/>}/>
            <Route path="/Article7" element={<Article7/>}/>
            <Route path="/Article8" element={<Article8/>}/>
            <Route path="/Article9" element={<Article9/>}/>
            <Route path="/Article10" element={<Article10/>}/>
            <Route path="/Article11" element={<Article11/>}/>
            <Route path="/Article12" element={<Article12/>}/>
            <Route path="/Article13" element={<Article13/>}/>
            <Route path="/Article14" element={<Article14/>}/>
            <Route path="/Article15" element={<Article15/>}/>
            <Route path="/Article16" element={<Article16/>}/>

            {/*Генераторы*/}
            <Route path="/Generator1" element={<Generator1/>}/>
            <Route path="/Generator2" element={<Generator2/>}/>
            <Route path="/Generator3" element={<Generator3/>}/>
            <Route path="/Generator4" element={<Generator4/>}/>
            <Route path="/Generator5" element={<Generator5/>}/>
            <Route path="/Generator6" element={<Generator6/>}/>
            <Route path="/Generator7" element={<Generator7/>}/>
            <Route path="/Generator8" element={<Generator8/>}/>
            <Route path="/Generator9" element={<Generator9/>}/>
            <Route path="/Generator10" element={<Generator10/>}/>
            <Route path="/Generator11" element={<Generator11/>}/>
            <Route path="/Generator12" element={<Generator12/>}/>

            {/*Компрессоры*/}
            <Route path="/Kompressor1" element={<Kompressor1/>}/>
            <Route path="/Kompressor2" element={<Kompressor2/>}/>
            <Route path="/Kompressor3" element={<Kompressor3/>}/>
            <Route path="/Kompressor4" element={<Kompressor4/>}/>
            <Route path="/Kompressor5" element={<Kompressor5/>}/>
            <Route path="/Kompressor6" element={<Kompressor6/>}/>
            <Route path="/Kompressor7" element={<Kompressor7/>}/>
            <Route path="/Kompressor8" element={<Kompressor8/>}/>

            {/*Электрические компрессоры*/}
            <Route path="/elec_kompressor1" element={<Podemnik1/>}/>
            <Route path="/elec_kompressor2" element={<Podemnik2/>}/>
            <Route path="/elec_kompressor3" element={<Podemnik3/>}/>

            {/*Манипуляторы*/}
            <Route path="/manipulator1" element={<Manipulator1/>}/>
            <Route path="/manipulator2" element={<Manipulator2/>}/>
            {/*Продажа Техники*/}
            <Route path="/used-maschinery1" element={<Selltech1/>}/>

            <Route path="/contacts" element={<Contact/>}/>
            <Route path="/generator" element={<MainGeneratory/>}/>
            <Route path="/disel-kompressor" element={<MainKompressori/>}/>
            <Route path="/manipulator" element={<MainManipulatori/>}/>
            <Route path="/elek-kompressor" element={<MainPodemniki/>}/>
            <Route path="/used-maschinery" element={<MainSellTech/>}/>
            <Route path="/PrivacyPolitic" element={<PrivacyPolitic/>}/>
            <Route path="/FAQ" element={<QuestionsAnswers/>}/>
          </Routes>
          <Contactsmini/>
          <Footer/>
        </div>


      </BrowserRouter>

    )
  
}

export default App