import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article4 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Как правильно выбрать дизельный генератор для загородного дома</div>
                    <div className='Articles__container1'>
                    <p className="Article__container-item">
                        Задача любой статьи — дать объективную информацию, и эта статья не исключение, её главная задача — рассказать о том, какие предложения будут ожидать вас на рынке и о том, что обычно советуют специалисты. Ведь всё дело не в том, чтобы просто выбрать дорогой или дешёвый дизель
                    </p>
                    <p className="Article__container-item">
                        Задача любой статьи — дать объективную информацию, и эта статья не исключение, её главная задача — рассказать о том, какие предложения будут ожидать вас на рынке и о том, что обычно советуют специалисты. Ведь всё дело не в том, чтобы просто выбрать дорогой или дешёвый дизель генератор — важно чтобы его выбор был осознан и обоснован.
                    </p>
                    <p className="Article__container-item">
                        Когда возникает необходимость в поекпке или аренде дизель генераторов неискушенного клиента обычно интересуют всего-лишь две вещи — цена и мощность электростанции. Некоторые правда ещё интересуются потреблением топлива и уровнем шума. Однако и аренда дизельных электростанций и их покупка — не самое дешёвое удовольствие, поэтому при выборе лучше рассмотреть больше критериев и в итоге найти генератор полностью удовлетворяющий ваши цели и потребности.
                    </p>
                    <p className="Article__container-item">
                        Для этого необходимо рассмотреть и рассказать обо всех характеристиках важных при выборе генератора.
                    </p>
                    <p className="Article__container-item">
                        Первым и безусловно важным моментов является правильный расчёт необходимой мощности. Для начала нужно выяснить, будет ли генератор питать весь дом или в качестве резервного источника энергии только основные узлы (свет, тепло, вентиляция и т.п.), есть ли с среди установленного на объекте оборудования сложные для генератора приборы, такие как насосы или любые типы электродвигателей. Эту задачу лучше всего доверить специалисту который обследует объект и даст вам чёткие рекомендации.
                    </p>
                    <p className="Article__container-item">
                        Следующим шагом необходимо определиться с типом генератора.
                    </p>
                    <p className="Article__container-item">
                        Любая электростанции состоит из соосно соединённых и укреплённых двигателя и генератора. Запуск двигателя может осуществляться как автоматически (при внезапном падении мощности в основной электро сети) так и вручную, с помощью электростартера или шнуром.
                    </p>
                    <p className="Article__container-item">
                        Долговечность работы генератора в основном зависит от надёжности его двигателя. Поэтому мы рассмотрим здесь только генераторы основанные на дизельных двигателях, поскольку они хотя и дороже своих бензиновых аналогов, за то гораздо более надёжны и экономичны, однако если вопрос цены стоит у вас на первом месте, то можно предложить вам рассмотреть вариант аренды дизель генераторов.
                    </p>
                    <p className="Article__container__subtittle">
                        Группы генераторов
                    </p>
                    <p className="Article__container-item">
                        Дизельные электростанции делятся на две большие группы — с жидкостным и воздушным охлаждением.
                    </p>
                    <p className="Article__container-item">
                        Генераторы с воздушным охлаждением занимают более низкую позицию чем электростанции с жидкостным охлаждением, но всё равно не стоит сбрасывать их со счетов.
                    </p>
                    <p className="Article__container-item">
                        Среди маломощных дизельных генераторов бессменным лидером является японская фирма Yanmar, зарекомендовавшая себя как производитель неприхотливых и надёжных двигателей, которые плюс ко всем отлично работают на отечественном дизельном топливе. Среди более мощных двигателей уже нету явных лидеров — фирм и моделей много, и все, конечно, стараются быть лучшими. Можно обратить внимание на итальянскую фирму Lombardini (главное чтобы такой двигатель был не турецкой сборки), итальянскую же Ruggerini или немецкую Hatz, но все они капризны и серьёзно не дотягивают до серьёзных дизельных генераторов с жидкостным охлаждением.
                    </p>
                    <p className="Article__container-item">
                        Генераторы с жидкостным охлаждением гораздо более надёжны и долговечны (наработка на отказ состоявляет 20.000-40.000 часов против 5.000 часов у генераторов с воздушным охлаждением) и используются в электростанциях более высокого класса.
                    </p>
                    <p className="Article__container-item">
                        Важным параметром является выбор высоко (3000 об/мин) или низко оборотистого двигателя (1500 об/мин). «Быстроходные» двигатели дешевле, отличаются меньшими габаритами, но и более высоким расходом топлива и меньшим ресурсом. Низко оборотистые двигатели же можно использовать для круглосуточной работы 365 дней в году.
                    </p>  
                    <p className="Article__container-item">
                        Выбор фирм изготовителей здесь так же велик: для средне мощных электростанций (от 6 до 20 кВт) можно использовать хорошо зарекомендовавшие себя двигатели от Mitsubishi, а для более мощных (от 20 до 180 кВт) подойдут двигатели американской компании John Deere. Самыми же надёжными и долговечными по праву считаются дизельные двигатели английской компании Perkins. Они хоть и менее изящны чем японские Mitsubishi, за то имеют ресурс работы на отказ до 40.000 часов и в этом плане у них просто нету конкурентов.
                    </p>
                    <p className="Article__container-item">
                        После того как вы наконец определились с двигателем, необходимо выбрать сам генератор.
                    </p>
                    <p className="Article__container__subtittle">
                        Типы генераторов
                    </p>
                    <p className="Article__container-item">
                        Они бывают двух типов — синхронные и асинхронные. Вторые дешевле, но говорить о качестве электричества при их использовании не приходиться. К тому же они не способны выдерживать пиковых нагрузок, поэтому запуск в подключённой к такому генератору сети любого электродвигателя (например холодильника) может вывести генератор из строя, в то время как синхронные генераторы способны переносить 3-кратные мгновенные перегрузки.
                    </p>
                    <p className="Article__container-item">
                        В профессиональных и стационарных электростанциях устанавливаются только синхронные и бесщеточные необслуживаемые генераторы признанных лидеров (французский Leroy Somer, английский Newage Stamford, итальянский Mecc Alte). У этих производителей огромные объемы производства, давнишняя история поставок в Россию, развитый сервис. Внимательнее относитесь именно к марке и производителю генератора, а не просто смотрите страну изготовления.
                    </p>
                    <p className="Article__container-item">
                        Покупая автомобиль вас всегда интересуют дополнительные опции. Они есть у генераторов и дарят широкие возможности, правда и серьёзно увеличивают его конечную стоимость. Но если опции для вас важны, а вопрос цены критичен, то не стоит отчаиваться — аренда дизельгенератора — широко развитая отрасль.
                    </p>
                    <p className="Article__container-item">
                        К дополнительным опциям относятся возможность автоматического запуска генератора, контроля за его параметрами на ЖК-дисплее или удалённом компьютере, различные системы защиты от перегрузок, утечек и атмосферных явлений, увеличенные топливные баки, кожухи-укрытия, шумоизолирубщие кожухи, дополнительные глушители, арктические контейнеры и много другое.
                    </p>
                    <p className="Article__container-item">
                        И вот мы подошли к главному вопросу заданном в заголовке этой статьи. 
                    </p>
                    <p className="Article__container__subtittle">
                        Какой же генератор поставить в загородный дом?
                    </p>
                    <p className="Article__container-item">
                        Естественно, самими главными параметрами будут надёжность, электрическая и пожарная безопасность, низкий уровень шума, длительный срок автономной работы и автоматический запуск при пропадании энергии в основной сети. Все это может гарантировать только дизельный двигатель с жидкостным охлаждением, поэтому нужно быть готовым к тому, что такая покупка обойдётся вам в 5.000 — 8.000 евро. Но если финансовый фактор играет немаловажную роль, то приходиться либо ограничивать свои требования к уровню качества и надёжности и приобретать более дешёвые модели или задуматься о возможности аренды дизельгенератора.
                    </p>
                    <p className="Article__container-item">
                        И так, разобравшись с типом двигателя и генератора, решив какие вам нужны дополнительные опции и рассчитав мощность пора покупать генератор. Но здесь оказывается тоже важно не ошибиться.
                    </p>
                    <p className="Article__container-item">
                        Важно найти солидную компанию, дающую полную и правдивую информацию о своем товаре. Обращаясь на рынок или в небольшую сомнительную фирму вы рискуете быть обманутым или введённым в заблуждение. Так некоторые продавцы выдают мощность электростанции в кВА (киловольт-амперах) за мощность в кВт (киловаттах), тем самым завышая полезную мощность генератора.
                    </p>
                    <p className="Article__container-item">
                        Так же следуют обратить внимание на уровенб шума в децибелах — часто просто не указанно, на каком расстояние дано значение (стандартно принято измерять шум на 1, 4 , 7 и 15 метрах) и на какой нагрузке (на холостом ходу или на номинале). Нормальными считаются цифры около 77–82 дБ на 7м для открытых дизельных электростанций (дизель генераторов) (при 3000 об/мин), 62–69 дБ на 7м для бензиновых электростанций (бензогенераторов) в кожухе и 67–71 дБ на 7м для мощных дизельных электростанций (дизель генераторов) в кожухе.
                    </p>
                    <p className="Article__container-item">
                        Наработка на отказ — 100.000 часов! Не верьте, таких генераторов не существует в природе. Наработка на отказ — это статистически средняя цифра времение безотказной работы генератора до первого капитального ремонта. Нормальные цифры для полупрофессиональных 1200 часов, профессиональных – 3000 часов. Для дизельных двигателей с воздушным охлаждением – около 5000 часов, а с жидкостным охлаждением (обладающие максимальным ресурсом и работающие на пониженных 1500 об/мин) – до 40 000 часов.
                    </p>
                    <p className="Article__container-item">
                        Выбор сделан и вы совершили покупку, остались сущие пустяки — установить оборудование на объекте. Тут правда тоже нужно решить, кому доверить эту работу.
                    </p>
                    <p className="Article__container-item">
                        Монтаж и подключение электростанции весьма сложная и специфическая задача, где ошибка или небрежность может обойтись достаточно дорого. Лучше, чтобы монтаж обеспечивала организация, которая продает оборудование. Иначе, как правило, потом будет трудно искать виноватых, ведь самое лучшее оборудование можно угробить непрофессиональным монтажом. По крайней мере, рекомендуем приглашать специалистов организации, которая впоследствии будет нести гарантийные обязательства, на шефмонтаж (выдаются рекомендации по выполнению монтажа, затем исправление сделанных при монтаже ошибок и приемка смонтированного агрегата с подписанием акта).
                    </p>
                </div>
            </div>
            </>
        );
    }
}
 
export default Article4;