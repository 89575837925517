
import React from 'react'

import profit1 from '../img/profit-icon1.png'
import profit2 from '../img/profit-icon2.png'
import profit3 from '../img/profit-icon3.png'
import profit4 from '../img/profit-icon4.png'

class Shortabout extends React.Component{
    render(){
        return(
            <div className='shortabout__wrapper'>
                <div className='shortabout__container'>
                    <h1 className='shortabout__tittle'>Аренда и продажа оборудования в Москве</h1>
                    <div className='section__shortabout'>
                        
                        <ul className='profit__list'>
                            <li className='profit__list-item'>
                                <img src={profit1} className='profit__list-item-image' alt='надежность'></img>
                                <div>Надежность от японского производителя с 80-летней историей</div>
                                
                            </li>
                            <li className='profit__list-item'>
                                <img src={profit2} className='profit__list-item-image' alt='соответствие'></img>
                                <div> Соответствие европейским стандартам качества, безопасности и экологичности</div>
                               
                            </li>
                            <li className='profit__list-item'>
                                <img src={profit3} className='profit__list-item-image' alt='выбор'></img>
                                <div>Более 36 видов оборудования под любые задачи</div>
                                
                            </li>
                            <li className='profit__list-item'>
                                <img src={profit4} className='profit__list-item-image' alt='аренда'></img>
                                <div>Возможность аренды как с нашим обслуживанием, так и без него</div>
                                
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Shortabout