import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article9 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Аренда дизельных генераторов DENYO и AIRMAN</div>
                    <div className='Articles__container1'>
                        <p className="Article__container__subtittle">
                        Аренда дизельных электростанций с обслуживанием
                        </p>
                        <p className="Article__container-item">
                            Возможны два варианта аренды генераторов.
                        </p>
                        <p className="Article__container-item">
                            В первом варианте мы:
                        </p>
                        <p className="Article__container-item">
                            предоставляем в аренду дизельный генератор с сертифицированной емкостью для хранения дизельного топлива
                        </p>
                        <p className="Article__container-item">
                            подключаем эту емкость к дизельному генератору для бесперебойной работы
                        </p>
                        <p className="Article__container-item">
                            завозим по необходимости топливо и систематически проводим контроль за работой дизельного генератора
                        </p>
                        <p className="Article__container-item">
                            Т.е., в данном варианте, Вы только глушите и заводите двигатель генератора по мере необходимости.
                        </p>
                        <p className="Article__container-item">
                            Во втором варианте к выше перечисленному добавляется оператор, который присутствует на объекте и контролирует работу дизельного генератора.
                        </p>
                        <p className="Article__container-item">
                            Второй вариант обычно применяется, если заказывается аренда электростанции мощностью свыше 100 кВт.
                        </p>
                        <p className="Article__container-item">
                            Наиболее оптимальный и выгодный для Арендатора первый вариант обслуживания, так как все наши дизельные генераторы японского производства оснащены необходимыми мерами защиты от возможной некорректной работы, имеют большой ресурс по наработке, а их запуск или остановка не сложнее чем в любом легковом автомобиле.
                        </p>
                        <p className="Article__container-item">
                            Отсутствие постоянного оператора позволит Вам сэкономить, например, при месячной аренде электростанции не менее семидесяти тысяч рублей, к тому же, вся экономия с топлива при непостоянной максимальной нагрузке останется у Вас, а это порядка тридцати процентов от затрат на солярку.
                        </p>
                        <p className="Article__container-item">
                            После заключения Договора аренды электростанции мы можем доставить технику на объект, дать необходимые консультации по её эксплуатации, если необходимо, то оказать услуги по подключению дизельного генератора. Плановое техническое обслуживание арендуемого дизельного генератора осуществляется нами, и не требует с Вашей стороны дополнительных расходов.
                        </p>
                        <p className="Article__container__subtittle">
                            Зачем выбирать дизельгенератор?
                        </p>
                        <p className="Article__container-item">
                            Дизельный генератор, в отличие от бензинового генератора, более экономичен и долговечен. Дизельгенераторы мало критичны к условиям эксплуатации, работают в широком диапазоне температур.
                        </p>
                        <p className="Article__container-item">
                            3-х фазные дизельные генераторы, с водяным охлаждением, могут работать в непрерывном режиме месяцами, в отличие от бензиновых, продолжительность работы которых не более 8 часов. Предоставляемые в аренду электростанции имеют шумоизоляцию, соответствующую ISO 9001, что позволяет использовать генераторы в черте города, вблизи жилых домов.
                        </p>
                        <p className="Article__container-item">
                            Автономные дизельные генераторы служат источниками электроэнергии в местах, где нет доступа или затруднительно получить централизованное энергоснабжение. Также они незаменимы там, где качество электроснабжения не удовлетворяет требуемому уровню. Дизельный генератор приспособлен к кратковременным перегрузкам и имеет выходное напряжение 380 В и 220 В.
                        </p>
                        <p className="Article__container-item">
                            Дизельные генераторы получили обширное применение. Они используются в качестве основного, либо резервного источника питания при строительстве, в больницах, предприятиях, узлах связи и так далее.
                        </p>
                        <p className="Article__container__subtittle">
                            Дизельные генераторы DENYO.
                        </p>
                        <p className="Article__container-item">
                            Дизельный генератор DENYO известен по всему миру, как один из самых надежных. Все генераторы отличаются отменным Японским качеством благодаря высокому качеству, как сборки, так и составных деталей. При проектировании генераторов используются уникальные, запатентованные технологии. Генераторные двигатели способны работать при 100% загрузке. Каждый генератор проходит строгую систему контроля, что исключает появления бракованных изделий. Благодаря всему этому дизельные генераторы имеют хорошие эксплуатационные характеристики.
                        </p>
                        <p className="Article__container-item">
                            Основные характеристики дизельных генераторов DENYO
                        </p>
                        <p className="Article__container-list">
                            Простой и быстрый запуск.
                        </p>
                        <p className="Article__container-list">
                            Японский дизельный двигатель с высокой надежностью и низким расходом топлива.
                        </p>
                        <p className="Article__container-list">
                            Круглосуточная работа в непрерывном режиме месяцами.
                        </p>
                        <p className="Article__container-list">
                            Стабильное выходное напряжение.
                        </p>
                        <p className="Article__container-list">
                            Применим для большого спектра климатических условий.
                        </p>
                        <p className="Article__container-list">
                            Высокое качество и стабильность.
                        </p>
                        <p className="Article__container__subtittle">
                            Дизельные генераторы AIRMAN.
                        </p>
                        <p className="Article__container-item">
                            Дизельный генератор AIRMAN отличается высоким Японским качеством. Он зарекомендовал себя как надежный помощник в тех областях, где требуется бесперебойное обеспечение электроэнергией.
                        </p>
                        <p className="Article__container-item">
                            Дизельные генераторы серии SDG созданы на базе без щеточного генератора с пониженным переходным сопротивлением и высоким КПД. Отклонение напряжения от идеальной синусоиды сведено к минимуму. Это делает генераторы AIRMAN универсальными. Они могут быть использованы во многих областях.
                        </p>
                        <p className="Article__container-item">
                            В дизельгенераторе AIRMAN используются двигатели только известных производителей, таки как: Isuzu,   Mitsubishi,   Nissan,   Shibaura,  Hino. Они отвечают все требованиям по охране окружающей среды, а также отличаются низким расходом топлива.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article9;