import {Link} from "react-router-dom";
import React from 'react';
class Footer extends React.Component {
    
    render() { 
        return (
            <div className='footer__container'>
                    <div className='footer__left'>© 2007-2023 «Машины и механизмы»</div>
                    <div className='footer__right'>
                        <Link to={'/PrivacyPolitic'} className='footer__link'>
                            Политика конфиденциальности
                        </Link>
                    </div>
            </div>
        );
    }
}
 
export default Footer;