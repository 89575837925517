import React from "react"
import ReactDOM from "react-dom"
import App from './App'
import './css/main.css'
import './css/header.css'
import './css/video.css'
import './css/catalog.css'
import './css/shortabout.css'
import './css/mainpagecatalog.css'
import "./css/subslider.css"
import "./css/popup.css"
import "./css/workinfo.css"
import "./css/feedbackmini.css"
import "./css/aboutus.css"
import "./css/moreinfo.css"
import "./css/partnerslider.css"
import "./css/footer.css"
import "./css/contactsmini.css"
import "./css/PrivacyPolitics.css"
import "./css/about.css"
import "./css/generatory.css"
import "./css/kompressory.css"
import "./css/article.css"
import "./css/podemniki.css"
import "./css/item.css"
import "./css/mobilemenu.css"
const app = ReactDOM.createRoot(document.getElementById("app"))

app.render(<App/>)