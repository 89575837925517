import React from "react"
import video from "../img/video.mp4"
import airmanlogo1 from "../img/airmanlogo1.png"
class Video extends React.Component{
   render(){
    return(
        <div>
            <div className="banner__bg">
                <div className="banner__container">
                    <img className="banner__img" src={airmanlogo1} alt="logo"></img>
                    <div className="banner__text__block">
                        <div className="banner__text__block-tittle">Сделано в Японии</div>
                        
                    </div>
                </div>
                <div className="video__block">
                    <video className="video" autoPlay loop muted allow="autoplay">
                        <source typeof="video/mp4"  src={video}/>                
                    </video>
                </div>
                
            </div>

        </div>
    )
   }
}

export default Video