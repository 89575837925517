import React from 'react';
import { Placemark, YMaps, Map  } from '@pbe/react-yandex-maps';
import pdf from '../../img/pdf.png'
class Adress extends React.Component {
    state = {  } 
    render() { 
        return (
            <div>
                   <div className="contacts__subcontainer">
                            <div className="contacts__subcontainer-list">
                                <div className="contacts__subcontainer-tittle" >ООО «Машины и механизмы»</div>
                                <div className="contacts__subcontainer-adress">121609, Москва, ул. Осенняя, д. 17/2, пом.1, ком.30,31</div>
                                <a className="contacts__subcontainer-phone" href="tel:+74957737177">+7 (495) 773-71-77</a>
                                <a className="contacts__subcontainer-phone" href="tel:+79257154820">+7 (925) 715-48-20</a>
                                <div className="contacts__subcontainer-mail ">Email: 7737177@mail.ru</div>
                            </div>
                            <div className="contacts__subcontainer-docs">
                                <a className="contacts__subcontainer-card" href="https://disk.yandex.ru/i/6Ru8umqzuJsKaQ" target='_blank' rel="noopener noreferrer">
                                    <img className="pdf__icon" src={pdf} alt='pdf'></img>
                                    <div className="contacts__card-block">
                                        <div>Договор Аренды</div>
                                        <p>Скачать pdf</p>
                                    </div>
                                </a>
                                <a className="contacts__subcontainer-card" href="https://disk.yandex.ru/i/Gw0j5h60bpzzVQ" target='_blank' rel="noopener noreferrer">
                                    <img className="pdf__icon" src={pdf} alt='pdf'></img>
                                    <div className="contacts__card-block">
                                        <div>Карточка предприятия</div>
                                        <p>Скачать pdf</p>
                                    </div>
                                </a>
                                <a className="contacts__subcontainer-card" href="https://disk.yandex.ru/i/6M5E0IPCq-0fMQ" target='_blank' rel="noopener noreferrer">
                                    <img className="pdf__icon" src={pdf} alt='pdf'></img>
                                    <div className="contacts__card-block">
                                        <div>Карточка предприятия</div>
                                        <p>Скачать pdf</p>
                                    </div>
                                </a>
                                
                                
                            </div>
                </div>
                <YMaps >
                    <Map defaultState={{ center: [55.765245, 37.403710], zoom: 13 }} className='yandex__map'>
                        <Placemark defaultGeometry={[55.765245, 37.403710]}/>
                    </Map>
                </YMaps>
            </div>
         
        );
    }
}
 
export default Adress;