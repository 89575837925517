import React from "react"
import Catalog from "../components/catalog"
import HeaderSubSpace from "../components/helpcomponents/headerspace"
import {Link} from "react-router-dom";
import { useState } from "react";
import Generator1 from "../img/generator1.jpg"
import Generator2 from "../img/generator2.jpg"
import Generator3 from "../img/generator3.png"
import Generator4 from "../img/generator4.jpg"
import Generator5 from "../img/generator5.jpg"
import Generator6 from "../img/generator6.jpg"
import Generator7 from "../img/generator7.jpg"
import Generator8 from "../img/generator8.jpg"
import Generator9 from "../img/generator9.jpg"
import Generator10 from "../img/generator10.jpg"
import Generator11 from "../img/generator10.jpg"
import Generator12 from "../img/generator12.jpg"
import Popup from "../components/popup1";
import Moreinfo from "../components/moreinfo"
import { Helmet } from "react-helmet";

const MainGeneratory =()=>{
    const handleButtonClick = () => {
        window.scrollTo(0, 0);
    }
    
    const [popupActive, setPopUpActive] = useState(false)
        return(
            <> 
            <Helmet title="Каталог генераторов"></Helmet>
                <HeaderSubSpace/>
                <Catalog/>
                <div className="generatory__wrapper">
                    <div className="generator__tittle">Аренда дизельного генератора</div>
                    <div className="generatory__container">
                        <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                        <Link to='/Generator1' className="gs__item__top-link">
                                            <img src={Generator1} className="gs__item__top-pic" alt="аренда Airman SDG 25 S"></img>
                                        </Link>
                                    
                                    </div> 
                                    <div className="gS__item__bottom">
                                        <Link to='/Generator1' className="gs__item__bottom-tittle" > <p >Дизельный генератор <br/> Airman SDG 25 S</p> </Link>
                                        <div className="gs__item__bottom-power">16 кВт 20 kVa</div>
                                        <div className="gs__item__bottom-power">двигатель 26 л.с.</div>
                                        
                                    
                                    
                                    
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                            <Link to='/Generator2' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator2}  className="gs__item__top-pic" alt="аренда Denyo DCA 35"></img>
                                            </Link>
                                        </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator2' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/>Denyo DCA 35</p></Link>
                                            <div className="gs__item__bottom-power">24 кВт 30 kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 45 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                        <Link to='/Generator3' onClick={handleButtonClick} className="gs__item__top-link">
                                            <img src={Generator3} className="gs__item__top-pic" alt="аренда Denyo DCA 45 S"></img>
                                        </Link>
                                    </div> 
                                    <div className="gS__item__bottom">
                                        <Link to='/Generator3' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор <br/>Denyo DCA 45 S</p> </Link>
                                        <div className="gs__item__bottom-power">30 кВт 38 kVa</div>
                                        <div className="gs__item__bottom-power">двигатель 46 л.с.</div>
                                        
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                            <Link to='/Generator4' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator4} className="gs__item__top-pic" alt="аренда Airman SDG 60 S"></img>
                                            </Link>
                                        </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator4' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Airman SDG 60 S</p></Link>
                                            <div className="gs__item__bottom-power">40 кВт 50 kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 66 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top" >
                                            <Link to='/Generator5' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator5} className="gs__item__top-pic" alt=" аренда Denyo DCA 75"></img>
                                            </Link>
                                    </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator5' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Denyo DCA 75</p></Link>
                                            <div className="gs__item__bottom-power">50 кВт 65kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 80 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top" >
                                            <Link to='/Generator6' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator6} className="gs__item__top-pic" alt="аренда Airman SDG 100 S"></img>
                                            </Link>
                                    </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator6' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Airman SDG 100 S</p></Link>
                                            <div className="gs__item__bottom-power">64 кВт 80 kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 100 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                            <Link to='/Generator7' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator7} className="gs__item__top-pic"alt="аренда Airman SDG 150 S"></img>
                                            </Link>
                                        </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator7' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Airman SDG 150 S</p></Link>
                                            <div className="gs__item__bottom-power">100 кВт 125kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 160 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                            <Link to='/Generator8' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator8} className="gs__item__top-pic" alt="аренда Airman SDG 220 S"></img>
                                            </Link>
                                        </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator8' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор<br/> Airman SDG 220 S</p></Link>
                                            <div className="gs__item__bottom-power">155 кВт 220kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 246 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                            <Link to='/Generator9' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator9} className="gs__item__top-pic" alt="аренда Airman SDG 300 S"></img>
                                            </Link>
                                        </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator9' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор <br/>Airman SDG 300 S</p></Link>
                                            <div className="gs__item__bottom-power">216 кВт 270kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 330 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                            <Link to='/Generator10' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator10} className="gs__item__top-pic"alt="аренда WILSON 380 E"></img>
                                            </Link>
                                        </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator10' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор <br/>WILSON 380 E</p></Link>
                                            <div className="gs__item__bottom-power">216 кВт 270kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 400 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                            <Link to='/Generator11' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator11} className="gs__item__top-pic" alt="аренда WILSON 700 E"></img>
                                            </Link>
                                        </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator11' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор <br/>WILSON 700 E</p></Link>
                                            <div className="gs__item__bottom-power">508 кВт 635kVa</div>
                                            <div className="gs__item__bottom-power">двигатель 720 л.с.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>

                                <div className="gS__container-item1">
                                    <div className="gS__item__top">
                                            <Link to='/Generator12' className="gs__item__top-link" onClick={handleButtonClick}>
                                                <img src={Generator12} className="gs__item__top-pic" alt="аренда Cummins C500D5"></img>
                                            </Link>
                                        </div> 
                                        <div className="gS__item__bottom">
                                            <Link to='/Generator12' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный генератор <br/>Cummins C500D5</p></Link>
                                            <div className="gs__item__bottom-power">400 кВт </div>
                                            <div className="gs__item__bottom-power">двигатель 	Cummins QSX15G8.</div>
                                            
                                    </div>
                                    <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                                </div>
                    </div>
                    <div className="generatory__text">
                        <p className="generatory__text-item">Если компьютеры в офисе или бытовая техника в магазине нуждаются в подключении к электросети, и вы постоянно сталкиваетесь с перебоями, то стоит только взять в аренду генератор, и вы увидите, как быстро решится эта проблема. Ждать включения централизованного электроснабжения больше не придётся!</p>
                        <p className="generatory__text-tittle">Аренда дизельных генераторов</p>
                        <p className="generatory__text-item">Главная функция— это обеспечение электропитанием больших зданий: торговых центров, коттеджей, заводов и т.п. Беспрерывно оборудование может работать до двух месяцев.</p>
                        <p className="generatory__text-item">Мощность варьируется от 20 до 2400 кВт. Из-за воспроизводимого шума и больших габаритов, многие модели оснащены защитными кожухами. Они изолируют шум и защищают от неблагоприятных условий.</p>
                        <p className="generatory__text-item">Основные характеристики, которые нужно знать при заказе аренды дизель генератора в Москве:</p>
                        <ul className="generatory__text-list">
                            <li className="generatory__text-item">Мощность станции. При аренде дизельных генераторов цена зависит от мощности. Для верного выбора необходимо знать число подключаемых электроприборов и их потребление. Брать генератор необходимо с мощностью побольше, чтобы станция не работала в полную силу постоянно.</li>
                            <li className="generatory__text-item">Фаза. Есть два вида – однофазные и трёхфазные, 220В и 380В. Первый используется для маленьких строительных работ или для дома. Второй — для обеспечения электричеством больших зданий, коттеджей или предприятий.</li>
                            <li className="generatory__text-item">Система запуска. У генераторов с маленькой мощностью установлена ручная система включения. Более 6 кВт установлен дополнительный электростартер, где вы нажали на кнопочку — и генератор сам уже работает. Последний и самый практичный способ включения — автоматический. Модель сама включается при отключении электропитания и выключается при возобновлении электроснабжения в централизованной сети.</li>
                        </ul>
                        <p className="generatory__text-tittle">Плюсы аренды генераторов в Москве</p>
                        <ul className="generatory__text-list">
                            <li className="generatory__text-item">При аренде генератора цена, которую вы заплатите ниже, чем при покупке новой электростанции;</li>
                            <li className="generatory__text-item">Мы сами отвечаем за место для хранения;</li>
                            <li className="generatory__text-item">Все электростанции проходят плановые технические осмотры и регулярно обслуживаются.</li>
                        </ul>
                    </div>
                    
                </div>
                <Moreinfo/>
                     
                <Popup  active={popupActive} setActive={setPopUpActive} />
            </>
           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        )
}


export default MainGeneratory