import{Link} from "react-router-dom";
import React, { useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
const Popup = ({active, setActive})=>{

    const [successActive, setSuccessActive] = useState(false)
    const [popupActive, setPopUpActive] = useState(true)
    const form = useRef();
    function switcher(){
        setActive(false)
        setSuccessActive(false)
    }
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_s2vi111', 'template_vms8jwp', form.current, '07aR0p5Xyc5vKrCKc')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
    return(
        <div className={active ? "popup1 active":"popup1 "} onClick={() => switcher()}>
            
            <div className={active ? "popup1__content active":"popup1__content "} onClick={e => e.stopPropagation()}>
                <button className="popup__crossbar" onClick={()=>switcher()}>×</button>
                <div className="popup__wrapper">
                    
                    <div className="popup__tittle">Оставить заявку</div>
                    <div className="popup__subtittle">Оставьте свои контактные данные, и мы свяжемся с вами</div>
                    <form ref={form}className="popup__form" onSubmit={sendEmail} >
                        <input className="popup__form-item" placeholder="Ваше имя" type="text" name="name" ></input>
                        <input className="popup__form-item"placeholder="E-mail" type="email" name="email" ></input>
                        <input className="popup__form-item" placeholder="+7 (___) ___-__-__" name="number" ></input>  
                        <input className="popup__button"  type="submit" value="Отправить" onClick={()=>{setSuccessActive(true)}}/>
                        <div className={successActive? "success__message":"success__message nonactive"}>Сообщение отправлено</div>             
                    </form>
                        <div className='popup__check'>
                            
                            <div className="popup__warning">Нажимая на кнопку, вы даете согласие на обработку своих персональных данных и соглашаетесь с <Link onClick={() => setActive(false)} to={'/PrivacyPolitic'}>политикой конфиденциальности</Link> .</div>
                        </div>
                        
                                        
                </div>

            </div>
            
        </div>
    )
}

export default Popup;