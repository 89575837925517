import React from "react"
import Catalog from "../components/catalog"
import HeaderSubSpace from "../components/helpcomponents/headerspace"
import {Link} from "react-router-dom";
import Popup from "../components/popup1";
import { useState } from "react";

import Kompressor1 from "../img/elek-kompressor1.jpg"
import Kompressor2 from "../img/elek-kompressor2.jpg"
import Kompressor3 from "../img/elek-kompressor3.jpg"
import Podemnik8 from "../img/podemnik8.jpg"

import Moreinfo from "../components/moreinfo"
import { Helmet } from "react-helmet";
const MainPodemniki =()=>{
    const handleButtonClick = () => {
        window.scrollTo(0, 0);;
    }  
        const [popupActive, setPopUpActive] = useState(false)
        return(
            <> 
            <Helmet title="Каталог электрических компрессоров"></Helmet>
                <HeaderSubSpace/>
                <Catalog/>
                <div className="kompressory__wrapper">
                    <div className="podemniki__tittle">
                        Аренда электрического компрессора
                    </div>
                    <p className="podemniki__subtittle">На гусеничном и колесном ходу</p>
                    <div className="kompressory__container">
                        
                        

                        <div className="gS__container-item2">
                            <div className="gS__item__top1">
                                    <Link className="gs__item__top-link" onClick={handleButtonClick} to='/elec_kompressor1'>
                                        <img src={Kompressor1} className="gs__item__top-pic1" alt="аренда подъемник ENCL068S-3"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link className="gs__item__bottom-tittle" to='/elec_kompressor1'><p onClick={handleButtonClick}>Электрический <br/> компрессор SMS22RD</p></Link>
                                    <div className="gs__item__bottom-power">
                                        <div className="gs__item__bottom-power">4.1 м³ в минуту</div>
                                        <div className="gs__item__bottom-power">7.14 кг/см²</div>
                                    </div>
                                    <div className="gs__item__bottom-price">
                                        <div className="gs__item__bottom-price-t">Аренда:</div>
                                        <div className="gs__item__bottom-price-c">от 2600 ₽/сутки</div>
                                    </div>
                                    
                            </div>
                            <button className="gs__item__bottom-button"onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>
                        <div className="gS__container-item2">
                            <div className="gS__item__top1">
                                    <Link className="gs__item__top-link" onClick={handleButtonClick} to='/elec_kompressor2'>
                                        <img src={Kompressor2} className="gs__item__top-pic1" alt="аренда подъемник ENTL080S-3"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link className="gs__item__bottom-tittle" to='/elec_kompressor2'><p onClick={handleButtonClick}>Электрический <br/> компрессор SMS37RD</p></Link>
                                    <div className="gs__item__bottom-power">
                                        <div className="gs__item__bottom-power">6.9 м³ в минуту</div>
                                        <div className="gs__item__bottom-power">7.14 кг/см²</div>
                                    </div>
                                    <div className="gs__item__bottom-price">
                                        <div className="gs__item__bottom-price-t">Аренда:</div>
                                        <div className="gs__item__bottom-price-c">от 3600 ₽/сутки</div>
                                    </div>
                                    
                            </div>
                            <button className="gs__item__bottom-button"onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>
                        <div className="gS__container-item2">
                            <div className="gS__item__top1">
                                    <Link className="gs__item__top-link" onClick={handleButtonClick} to='/elec_kompressor3'>
                                        <img src={Kompressor3} className="gs__item__top-pic1" alt="аренда подъемник ENTL080S-3"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link className="gs__item__bottom-tittle" to='/elec_kompressor3'><p onClick={handleButtonClick}>Электрический <br/> компрессор SMS75RD</p></Link>
                                    <div className="gs__item__bottom-power">
                                        <div className="gs__item__bottom-power">13.9 м³ в минуту</div>
                                        <div className="gs__item__bottom-power">7.14 кг/см²</div>
                                    </div>
                                    <div className="gs__item__bottom-price">
                                        <div className="gs__item__bottom-price-t">Аренда:</div>
                                        <div className="gs__item__bottom-price-c">от 4600 ₽/сутки</div>
                                    </div>
                                    
                            </div>
                            <button className="gs__item__bottom-button"onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>
                    </div>
                    
                        
                        
                    
                    <div className="generatory__text">
                        <p className="generatory__text-tittle">Аренда электрического компрессора</p>
                        <p className="generatory__text-item">
                            Аренда компрессора незаменима как в строительных и промышленных целях, ведь покупка электрического компрессора не всегда целесообразна из-за достаточно высокой стоимости и определённых условий хранения.</p>
                        <p className="generatory__text-item">Для работ, не связанных с постоянной необходимостью в аппарате, аренда электрического компрессора будет гораздо более удобным вариантом.</p>
                        <p className="generatory__text-item">Прокат техники является выгодной услугой по ряду причин:</p>
                        
                        <ul className="generatory__text-list">
                            <li className="generatory__text-item">техника доставляется к месту работы, устанавливается и подключается специалистами;</li>
                            <li className="generatory__text-item">нет необходимости в оборудовании места для хранения;</li>
                            <li className="generatory__text-item">отсутствие затрат на обслуживание и ремонт техники;</li>
                            <li className="generatory__text-item">в аренду предоставляются компрессоры ведущих производителей, все аппараты имеют высокую производительность;</li>
                            <li className="generatory__text-item">поломка при естественном износе оборудования устраняется за счёт компании.</li>
                        </ul>
                        <p className="generatory__text-item">Перед началом работ, необходимо проконсультироваться со специалистами компании «Машины и механизмы» по вопросам работы с техникой и мерам предосторожности.</p>

                    </div>
                    
                    <Popup  active={popupActive} setActive={setPopUpActive} />

                </div>
                <Moreinfo/>
            </>
           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        )
    
}

export default MainPodemniki