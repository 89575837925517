import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article13 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Дизельгенераторы — это наша работа!</div>
                    <div className='Articles__container1'>
                        <p className='Article__container-item'>Аренда дизельных генераторов— основное направление деятельности нашей компании. Мы предлагаем нашим клиентам дизельгенераторы от ведущих мировых производителей, различных мощностей и комплектаций, а так же оказываем полную техническую поддержку нашего оборудования.</p>
                        <p className='Article__container-item'>В нашей стране перебои с подачей электроэнергии — далеко не единичный случай, а постоянная проблема, которая особенно остро касается регионов, в которых и расположена большая часть производственных предприятий, для которых даже краткосрочные проблемы с электропитанием могут вылиться в серьёзные проблемы, начиная от срыва технологических процессов, заканчивая поломкой дорогостоящего оборудования.</p>
                        <p className='Article__container-item'>Аренда генераторов в компании «УМ-77» - единственно верное решение, которое поможет вам и вашему предприятию избежать подобных проблем и получить гарантию бесперебойного питания своих объектов. </p>
                        <p className='Article__container-item'>Помимо производственных сфер, аренда электростанций как услуга часто востребована строительными и торговыми компаниями, а так же добывающей промышленностью. Так как при полном отсутствии электроэнергии с любым современным оборудованием просто невозможно работать. Так же аренда генераторов пользуется повышенным спросом у владельцев загородных домов и коттеджей и в небольших населённых пунктах. Наши дизельгенераторы используются на различных объектах в качестве основных или резервных источников питания.</p>
                        <p className='Article__container-item'>Технически, дизельгенератор — это аппарат состоящий из дизельного двигателя и генератора электрического тока, собранных вместе на металлической раме и защищённых шумопоглощающим корпусом. Использования дизельгенераторов для вырабатывания электрического тока зачастую бывает даже более экономически выгодно нежели потребления энергии от централизованного источника.</p>
                        <p className='Article__container-item'>Аренда генератора позволяет вашей компании быстро решить все производственные проблемы связанные с нехваткой электроэнергии. Помимо прочего, это ещё и уникальная в своём роде возможность использовать дорогостоящий агрегат без необходимости его покупки. Плюс ко всему, аренда электростанции позволяет вам не тратить финансовые ресурсы на множество накладных расходов, таких как ремонт и техническое обслуживание установки, содержание склада запчастей и обучения персонала. Все эти вопросы наша компания берёт на себя — замена оборудования при неисправности, круглосуточная смена операторов следящих за работой системы и своевременная доставка топлива. Если дополнительный источник электроэнергии требуется вам на определённый промежуток времени, то аренда дизельгенератора — идеальное решение проблемы, требующие минимальных финансовых затрат.</p>
                        <p className='Article__container-item'>Аренда дизельных генераторов в нашей компании позволит вам в кротчайшие сроки, в течении одного рабочего дня, получить в своё распоряжение независимый источник энергоснабжения и начать, или возобновить, деятельность вашего предприятия. </p>
                        <p className='Article__container-item'>Аренда дизельных электростанций  у нас осуществляется на взаимовыгодных условиях, мы стараемся угодить каждому клиенту. Наши специалисты помогут вам подобрать энергетическое оборудование конкретно под ваши требования и характеристики, а так же предложат дополнительное оборудование, необходимое в зависимости от места будущего расположения установки (например — шумозащитные кожухи и дополнительные глушители если вы собираетесь использовать наше оборудование вблизи от жилых домов).</p>
                        <p className='Article__container-item'>Мы регулярно проводим техническое обслуживание всех имеющихся в нашем парке энергетических установок, поэтому обратившись в нашу компанию, вы можете быть уверены в том, что наше оборудование не подведёт вас в самый ответственный момент.</p>
                        <p className='Article__container-item'>Аренда генератора в «УМ-77» - это просто, удобно и выгодно! Звоните нам, станьте нашим постоянным клиентом и навсегда забудьте о проблемах связанных с подачей электроэнергии.</p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article13;