import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen10 from '../../img/generator10.jpg';
import { Helmet } from "react-helmet";
function Generator10() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>280 кВт 350 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо дизель PERKINS</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>50 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/50</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>800 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>5.050 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>4564 x 1600 x 2150</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
        <tr>
            <td>в сутки</td>
            <td>договорная</td>
        </tr>
         
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор Wilson 380 E"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 280 кВт Wilson 380 E</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>280 кВт 350 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо дизель PERKINS</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>50 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/50</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>800 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>5.050 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>4564 x 1600 x 2150</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
        <tr>
            <td>в сутки</td>
            <td>договорная</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen10} alt='Аренда дизельного генератора 280 кВт Wilson 380 E'></img>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator10;