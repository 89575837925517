import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen11 from '../../img/generator10.jpg';
import { Helmet } from "react-helmet";
function Generator11() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>508 кВт/ 635 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо дизель.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>35 - 90 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>400/50/1200А</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>1200 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>6.500 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>6000 х 1900 х 2900</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>от 30 суток</td>
            <td>по запросу</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор WILSON 700 E"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 508 кВт WILSON 700 E</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>508 кВт/ 635 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо дизель.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>35 - 90 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>400/50/1200А</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>1200 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>6.500 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>6000 х 1900 х 2900</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>от 30 суток</td>
            <td>по запросу</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen11} alt='Аренда дизельного генератора 508 кВт WILSON 700 E'></img>
            </div>
            <div className='item__desk'>
                <p className='Article__container-item'>Чтобы обеспечить объекты автономным электрообеспечением, можно воспользоваться двумя способами: арендой оборудования или приобретением электрогенератора. Последний вариант часто накладен, в особенности если перебои с электроэнергией случаются эпизодически. В таком случаенаиболее выгодным решением является аренда генератора на 400 кВт.</p>
                <p className='Article__container-subtitle'>Выгодная аренда генератора 400 кВт</p>
                <p className='Article__container-item'>Аренда имеет ряд преимуществ:</p>
                <p className='Article__container-list'>минимум вложений в обслуживание оборудования (ответственность за это лежит на арендодателе);</p>
                <p className='Article__container-list'>плата вносится ровно за то время, когда генератор используется;</p>
                <p className='Article__container-list'>для обеспечения бесперебойной работы не нужно трудоустраивать и обучать обслуживающий персонал;</p>
                <p className='Article__container-list'>возможность замены неисправного оборудования;</p>
                <p className='Article__container-list'>возможность выкупа отдельных моделей по остаточной цене.</p>
                <p className='Article__container-item'>Наше современное оборудование может обеспечить электроэнергию даже в самых неблагоприятных условиях! Обращайтесь к нам!</p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator11;