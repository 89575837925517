import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen3 from '../../img/generator3.png';
import { Helmet } from "react-helmet";
function Generator3() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>30 кВт 38 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>дизель 46 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>6.5 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/220 V 50/60 Гц 55 A</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>100 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1180 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2000 х 880 х 1250</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>2 850 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>3 600 руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>1 100 руб</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор DENYO DCA 45"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 30 кВт DENYO DCA 45</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>30 кВт 38 kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>дизель 46 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>6.5 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>380/220 V 50/60 Гц 55 A</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>100 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1180 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2000 х 880 х 1250</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>2 850 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>3 600 руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>1 100 руб</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen3} alt='Аренда дизельного генератора 30 кВт DENYO DCA 45'></img>
            </div>
            <div className='item__desk'>
                <p className='Article__container-item'>Приобретать дизельный генератор на 30 кВт модели Denyo DCA 45 самостоятельно не целесообразно.Поэтому наша компания, расположенная в Москве, предлагает выгодные цены на аренду.</p>
                <p className='Article__container__subtitle'>Предлагаем аренду генератора на 30 квт</p>
                <p className='Article__container-item'>Жизнь плохо поддаётся нашим планам и часто происходят непредвиденные события — поломка опоры ЛЭП короткое замыкание илидругие неудобные ситуации. Всё это может привести к потерям прибыли в бизнесе. В связи с этим, наличие автономного источника питания позволит избежать возможных проблем.</p>
                <p className='Article__container-item'>Такие генераторы часто используют владельцы частных построек в качестве основного или запасного поставщика электроэнергии, который автоматически включается при аварии на линии. Однако тратить значительные суммы, покупая механизм в личную собственность невыгодно, ведь существует аренда генератора в 30 кВт от компании «Машины и механизмы»! Обращайтесь, мы проконсультируем и поможем решить ваши проблемы!</p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator3;