import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import gen9 from '../../img/generator9.jpg';
import { Helmet } from "react-helmet";
function Generator9() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>216 кВт 270kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо-дизель 330 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>42 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>220/380 50/60 410А</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>490 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>4410 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>3980 х 1415 х 1790</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>от 8 300 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>от 10 500 руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>3 000 руб</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Дизельный генератор AIRMAN SDG 300 S"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Аренда дизельного генератора 200 кВт AIRMAN SDG 300 S</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Мощность</td>
            <td>216 кВт 270kVa</td>
        </tr>
                    <tr>
            <td>Двигатель</td>
            <td>турбо-дизель 330 л.с.</td>
        </tr>
                    <tr>
            <td>Расход под нагр. 75% (л. в час)</td>
            <td>42 л. в час</td>
        </tr>
                    <tr>
            <td>Напряжение/Частота /Сила тока</td>
            <td>220/380 50/60 410А</td>
        </tr>
                    <tr>
            <td>Объем бака (л)</td>
            <td>490 литров</td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>4410 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>3980 х 1415 х 1790</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки, ТО вкл</td>
            <td>от 8 300 руб</td>
        </tr>
                                                        <tr>
            <td>в сутки, ТО вкл</td>
            <td>от 10 500 руб с НДС</td>
        </tr>
                                                        <tr>
            <td>резерв + моточас 100 руб</td>
            <td>3 000 руб</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image' src={gen9} alt='Аренда дизельного генератора 200 кВт AIRMAN SDG 300 S'></img>
            </div>
            <div className='item__desk'>
                <p className='Article__container-item'>Не всегда при проведении различных работ и организации мероприятий удаётся подключиться к постоянному источнику электрической энергии. В этом случае единственным вариантом решения проблемы является аренда дизельного генератора на 200 кВт.</p>
                <p className='Article__container-subtitle'>Когда может потребоваться аренда генератора в 200 кВт?</p>
                <p className='Article__container-item'>Аренда требуется, если у вас:</p>
                <p className='Article__container-list'>Приобретённый участок не подключен к энергосистеме.</p>
                <p className='Article__container-list'>К постройке ещё не подключены коммуникации.</p>
                <p className='Article__container-list'>Началась организация развлекательной вечеринки вдали от линий энергоснабжения.</p>
                <p className='Article__container-item'>Аренда генератора на 200 кВт и цена, за которую её можно оформить в компании «Машины и механизмы», чрезвычайно выгодна и доступна. Специалисты компании за самое короткое время сделают наладку оборудования и проконсультируют по всем вопросам эксплуатации.</p>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Generator9;