import React from "react"
class SubSpaceHeader extends React.Component{
    render(){
        return(
            <div className="header__space"></div>
           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        )
    }
}

export default SubSpaceHeader