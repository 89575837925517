import React from 'react';
import HeaderSubSpace from "../components/helpcomponents/headerspace"
import Catalog from "../components/catalog"
import { Helmet } from 'react-helmet';

class PrivacyPolitic  extends React.Component {
    render() { 
        return (
            <div className='PrivacyPolitics'>
                <Helmet title="Машины и Механизмы - политика конфиденциальности"></Helmet>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='PP__container'>
                    <div className='PP__tittle'>
                        Политика конфиденциальности
                    </div>
                    <div className='PP__text'>
                        <p className='PP__text-item'>Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное согласие на обработку моих персональных данных компанией ООО «Машины и механизмы» (ИНН/КПП: 9731032526 / 773101001)(далее по тексту - Оператор).</p>
                        <p className='PP__text-item'>Персональные данные - любая информация, относящаяся к определенному или определяемому на основании такой информации физическому лицу.</p>
                        <p className='PP__text-item'>Настоящее Согласие выдано мною на обработку следующих персональных данных:</p>
                        <ul  className='PP__text-list' >
                            <li>ФИО / Контактное лицо</li>
                            <li>E-Mail</li>
                            <li>Телефон / Факс</li>
                            <li>IP-адрес</li>
                        </ul>
                        <p className='PP__text-item'>Согласие дано Оператору для совершения следующих действий с моими персональными данными с использованием средств автоматизации и/или без использования таких средств: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, а также осуществление любых иных действий, предусмотренных действующим законодательством РФ как неавтоматизированными, так и автоматизированными способами.</p>
                        <p className='PP__text-item'>Данное согласие дается Оператору для обработки моих персональных данных в следующих целях:</p>
                        <ul className='PP__text-list'>
                            <li>предоставление мне услуг/работ;</li>
                            <li>направление в мой адрес уведомлений, касающихся предоставляемых услуг/работ;</li>
                            <li>подготовка и направление ответов на мои запросы;</li>
                            <li>направление в мой адрес информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах Оператора.</li>
                        </ul>
                        <p className='PP__text-item'>Срок действия согласия является неограниченным. Вы можете в любой момент отозвать настоящее согласие, направив письменное уведомления на адрес: 121609, г. Москва, ул. Осенняя, д. 17 корпус 2, пом.1, ком.30,31 с пометкой «Отзыв согласия на обработку персональных данных».</p>
                        <p className='PP__text-item'>Обращаем ваше внимание, что отзыв согласия на обработку персональных данных влечёт за собой удаление Вашей учётной записи с сайта (um77.ru), а также уничтожение записей, содержащих ваши персональные данные, в системах обработки персональных данных компании ООО «Машины и механизмы», что может сделать невозможным пользование интернет-сервисами компании.</p>
                        <p className='PP__text-item'>Гарантирую, что представленная мной информация является полной, точной и достоверной, а также что при представлении информации не нарушаются действующее законодательство Российской Федерации, законные права и интересы третьих лиц. Вся представленная информация заполнена мною в отношении себя лично.</p>
                        <p className='PP__text-item'>Настоящее согласие действует в течение всего периода хранения персональных данных, если иное не предусмотрено законодательством Российской Федерации.</p>
                        <p className='PP__text-item'>Настоящее согласие действует в течение всего периода хранения персональных данных, если иное не предусмотрено законодательством Российской Федерации.</p>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default PrivacyPolitic;