import React from "react"
import Catalog from "../components/catalog"
import HeaderSubSpace from "../components/helpcomponents/headerspace"


import Adress from "../components/helpcomponents/adress";
import Moreinfo from "../components/moreinfo";
import { Helmet } from "react-helmet";
class Contact extends React.Component{
    constructor(props){
        super(props)

        this.state={
            MPcontent:  <Adress></Adress>,
            MPClass1: "MPcatalog__button MPactive",
            MPClass2: "MPcatalog__button",

        } 
        this.buttonClick1=this.buttonClick1.bind(this)
        this.buttonClick2=this.buttonClick2.bind(this)

    }

    render(){

        return(
                <>
                <Helmet title="Машины и Механизмы - контакты и карты"></Helmet>
                    <HeaderSubSpace/>
                    <Catalog/>
                    <div className="Map__container">
                        <div className='Contacts__tittle'>Контакты</div>
                        <ul className="Contacts__list">
                            <li className="MPcatalog__tab__list-item">
                                <button className={this.state.MPClass1} onClick={this.buttonClick1}>Адреса</button>
                            </li>
                            <li className="MPcatalog__tab__list-item">
                                <button className={this.state.MPClass2} onClick={this.buttonClick2}>Обратная связь</button>
                            </li>
                        </ul>
                    



                        <div>{this.state.MPcontent}</div>
                    </div>
                    
                </>  
            )
    }
    buttonClick1(){
        this.setState({MPcontent:

                            
            <Adress></Adress>
        })
        this.setState({MPClass1:"MPcatalog__button MPactive"})
        this.setState({MPClass2:"MPcatalog__button"})
    }
    buttonClick2(){
        this.setState({MPClass2:"MPcatalog__button MPactive"})
        this.setState({MPClass1:"MPcatalog__button"})

        this.setState({MPcontent:<Moreinfo></Moreinfo>})
    }

}  



export default Contact;