import React from 'react';
import HeaderSubSpace from "../components/helpcomponents/headerspace"
class QuestionsAnswers extends React.Component {

    render() { 
        return (
            
            <>
                <HeaderSubSpace/>
                вопросы и ответы
            </>
        );
    }
}
 
export default QuestionsAnswers;