import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article11 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Аренда генераторов без лишних хлопот</div>
                    <div className='Articles__container1'>
                        <p className='Article__container-item'>Без электричества не возможно представить себе жизнь современного города, отключение электроэнергии даже на короткий не значительный промежуток времени уже создаёт значительные неудобства, а зачастую и серьёзные проблемы. Однако до сих пор остаются такие места куда не дотянулись линии электропередач, и куда, скорее всего, не дотянуться — просто потому что это невозможно или экономические не выгодно. Но как быть, если электричество всё-таки нужно? Аренда дизельгенератора, вот самый простой способ.</p>
                        <p className='Article__container-item'>На самом деле, сфера применения передвижных дизельных генераторов и электростанций огромна: они незаменимы при строительстве, производстве, праздничных и спортивных мероприятиях, концертах, съемках кинофильмов, выставках и в торговых комплексах, при проведении различных массовых мероприятий на открытом воздухе и в любых других сферах деятельности в качестве основного, резервного или дополнительного источника бесперебойного питания, когда нет возможности подключиться к основной сети электроснабжения или её мощности недостаточно для выполнения необходимых задач.</p>
                        <p className='Article__container-item'>Но цена на дизельные генераторы довольно высока и не всегда экономически выгодно совершать его покупку. Он срочно нужен здесь и сейчас: провести массовое мероприятие, работы на местности, где нет централизованного энергоснабжения. В таком случае поможет аренда генераторов.</p>
                        <p className='Article__container-item'>Здесь есть и ряд существенных плюсов: возможность оперативно обеспечить подачу электроэнергии, существенно сэкономить средства на приобретении оборудования. Так же аренда электростанции освобождает вас от проблем связанных с ремонтом и техническим обслуживанием оборудования. Плюс ко всему, аренда дизель генераторов, даёт Вам уникальную возможность выбора модели необходимой для каждого конкретного случая, ведь даже не подкованный в техническом отношении человек прекрасно понимает, что для проведения небольшой презентации и рок-концерта на открытом воздухе потребуются генераторы разных мощностей и типов. Так дизельная электростанция компактного типа подойдет для организации автономного электроснабжения частных домов при тех или иных перерывах в электропитании от энергосистемы, а стационарная дизельная электростанция высокой мощности применяется для обеспечения резервного бесперебойного электроснабжения промышленных предприятий и больниц.</p>
                        <p className='Article__container-item'>Многие компании готовы предложить вам аренду дизельных электростанций различных мощностей, благодаря чему вы сможете выбрать модель исходя из ваших потребностей и существенно сэкономить не переплачивая за лишнюю неиспользованную мощность.</p>
                        <p className='Article__container-item'>Но мощность — не единственный критерий на который стоит ориентироваться при выборе генератора. Надежность, безопасность, экономичность, ресурс, простота в эксплуатации и обслуживании, уровень шума.</p>
                        <p className='Article__container-item'>Как правило, предлагается два варианта аренды генератора: либо предоставляется для работы только генератор, либо оказывается полное постоянное обслуживание арендуемых дизельных установок на объектах заказчика.</p>
                        <p className='Article__container-item'>Наиболее оптимальным и выгодным вариантом является аренда генератора без обслуживания, так как генераторы, оснащены необходимыми мерами защиты от возможной некорректной работы и имеют большой ресурс по наработке, а их запуск или остановка не сложнее чем в любом легковом автомобиле. Отсутствие постоянного оператора позволит вам существенно уменьшить затраты, например просто аренда генератора в месяц экономит порядка семидесяти тысяч рублей, к тому же вся экономия с топлива при непостоянной максимальной нагрузке останется у вас, а это порядка тридцати процентов от затрат на топливо.</p>
                        <p className='Article__container-item'>Однако в случаях аренды дизельных генераторов мощность выше 100 кВт обычно используется второй вариант — аренда электростанций с обслуживанием, когда на вашем объекте постоянно присутствует оператор контролирующий работу станции.</p>
                        <p className='Article__container-item'>Аренда генератора — это оптимальное решения для вашего бизнеса, способное существенно минимизировать затраты связанные с покупкой и обслуживанием генератора, закупкой топлива и монтажом станции.</p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article11;