import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article7 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Аренда генератора - как выбрать нужную установку?</div>
                    <div className='Articles__container1'>
                        <p className="Article__container-item">
                        У Вас возникла необходимость в аренде генератора? Что же, мы можем Вам помочь! Но для начала, Вам необходимо чётко обозначит область будущего применения оборудования и его цель. Ведь если Вам нужно полностью обеспечить какой-то объект электроэнергией или же просто сделать резервный источник для самых необходимых вещей (сигнализация, отопление, освещение), то Вам безусловно понадобятся генераторы различной мощности.
                        </p>
                        <p className="Article__container-item">
                            У Вас возникла необходимость в аренде генератора? Что же, мы можем Вам помочь! Но для начала, Вам необходимо чётко обозначит область будущего применения оборудования и его цель. Ведь если Вам нужно полностью обеспечить какой-то объект электроэнергией или же просто сделать резервный источник для самых необходимых вещей (сигнализация, отопление, освещение), то Вам безусловно понадобятся генераторы различной мощности.
                        </p>
                        <p className="Article__container-item">
                            Аренда электростанции — не самый дешёвый процесс, поэтому необходимо чётко рассчитать и выбрать его оптимальную мощность и другие важные параметры.
                        </p>
                        <p className="Article__container-item">
                            Во-первых, нужно определить, какие потребители будут одновременно подключены к генератору и рассчитать их совокупную потребляемую мощность. Её можно высчитать по паспортным данным изделия, однако стоит обратить особое внимание на устройства имеющие электромоторы — ведь для запуска электромотора требуется мощность в 2-3 раза превышающая заявленную номинальную, так называемая «пусковая». Следовательно, для подсчёта мощности выбираемого генератора необходимо взять утроенное значение номинальной мощности прибора с наибольшим электромотором, прибавить к нему мощность всех остальных используемых устройств и полученный результат увеличить на 10% - это и будет мощностью необходимого вам дизельного генератора.
                        </p>
                        <p className="Article__container-item">
                            Второе — это тип двигателя. Здесь необходимо выбрать из многих параметров, таких как ресурс, вес установки, пожароопасность, уровень шума, продолжительность непрерывной работы и т.д.
                        </p>
                        <p className="Article__container-item">
                            Тут так же важен и выясненный нами в первом шаге момент — необходимая мощность. Если требуется генератор выдающий более 15 кВА, то лучше посмотреть в сторону дизельных генераторов с жидкостным охлаждением. Кроме того, не менее важна в таком случае скорость вращения вала двигателя. Так у бензиновых моделей она составляет 3000 оборотов в минуту, в то время как дизельный генератор крутит вал со скоростью 1500 оборотов, благодаря чему может работать практически круглосуточно, при этом выделять меньше шума и иметь более высокий ресурс и длинный срок службы.
                        </p>
                        <p className="Article__container-item">
                            Третьим важны моментом является тип генератора: 220 или 400 вольт (одна или три фазы). Соответственно, 1-фазный генератор более прост и удобен в использовании, т.к. при использовании трёхфазного возникает необходимость следить за равномерным распределением нагрузок, поэтому если вы не планируете использовать 3-фазные потребители, то стоит взять в аренду генератор с одной фазой.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article7;