import React from 'react';
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Catalog from "../../components/catalog"
import { Helmet } from "react-helmet";
class Article2 extends React.Component {
    
    render() { 
        return (
            <>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Дизельный двигатель для генератора</div>
                    <div className='Articles__container1'>
                    <p className='Article__container-item'>Двигатель внутреннего сгорания, воспламенение топливной смеси в котором происходит под действием сжатия был изобретён Рудольфом Дизелем и назван в его честь. Главным отличием от других типов двигателей внутреннего сгорания является приготовление топливной смеси непосредственно в камере цилиндра, где под давлением поршня сжимается воздух, а его температура поднимается до 700-800 градусов, после чего под давлением через специальные форсунки подаётся дизельной топливо которое затем воспламеняется.</p>
                    <p className='Article__container-item'>Двигатель внутреннего сгорания, воспламенение топливной смеси в котором происходит под действием сжатия был изобретён Рудольфом Дизелем и назван в его честь.
                    </p>
                    <p className='Article__container-item'>Главным отличием от других типов двигателей внутреннего сгорания является приготовление топливной смеси непосредственно в камере цилиндра, где под давлением поршня сжимается воздух, а его температура поднимается до 700-800 градусов, после чего под давлением через специальные форсунки подаётся дизельной топливо которое затем воспламеняется.</p>
                    <p className='Article__container-item'>Благодаря этому дизельный двигатель имеет более высокий КПД и в совокупности с тем, что дизельное топливо имеет более низкую стоимость является более экономичным.</p>
                    <p className='Article__container-item'>Но не смотря на все положительный качества, двигатель изобретённый Дизелем имел и свои недостатки. Так, в частности, он имел довольно небольшую часто вращения и для нагнетания воздуха приходилось использовать отдельный компрессор, то это ошибка была исправлена немецким инженером Бошем, который усовершенствовав насос высокого давления и увеличил рабочие обороты дизельного двигателя, благодаря чему удалось отказаться от использования отдельного компрессора.</p>
                    <p className='Article__container-item'>Дизельный генератор был создан на основе этой технологии, и позволяет получить стабильное электропитание в отсутствии централизованного электроснабжения. В зависимости от мощности и видов охлаждения на рынке сегодня присутствуют различные виды дизельных генераторов.</p>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Article2;