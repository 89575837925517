import React from "react"
import Catalog from "../components/catalog"
import HeaderSubSpace from "../components/helpcomponents/headerspace"
import {Link} from "react-router-dom";
import { useState } from "react";
import Kompressor1 from "../img/kompressor1.jpg"
import Kompressor2 from "../img/kompressor2.jpg"
import Kompressor3 from "../img/kompressor3.jpg"
import Kompressor4 from "../img/kompressor4.jpg"
import Kompressor5 from "../img/kompressor5.jpg"
import Kompressor6 from "../img/kompressor6.jpg"
import Kompressor7 from "../img/kompressor7.jpg"
import Kompressor8 from "../img/kompressor8.jpg"
import for1 from "../img/for1.png"
import for2 from "../img/for2.png"
import for3 from "../img/for3.png"
import for4 from "../img/for4.png"
import for5 from "../img/for5.png"
import for6 from "../img/for6.png"
import Popup from "../components/popup1";
import Moreinfo from "../components/moreinfo"
import { Helmet } from "react-helmet";
const MainKompressori =()=>{
    const handleButtonClick = () => {
        window.scrollTo(0, 0);
    }
    
    const [popupActive, setPopUpActive] = useState(false)
        return(
            <> 
            <Helmet title="Каталог дизельных компрессоров"></Helmet>
                <HeaderSubSpace/>
                <Catalog/>
                <div className="kompressory__wrapper">
                    <div className="kompressory__tittle">Аренда дизельного компрессора</div>
                    <div className="kompressory__container">
                    <div className="gS__container-item2">
                            <div className="gS__item__top">
                                <Link to='/Kompressor1' className="gs__item__top-link" onClick={handleButtonClick}>
                                    <img src={Kompressor1} className="gs__item__top-pic" alt="аренда AIRMAN PDS 185 SC"></img>
                                </Link>
                               
                            </div> 
                            <div className="gS__item__bottom">
                                <Link to='/Kompressor1' className="gs__item__bottom-tittle"> <p onClick={handleButtonClick}>Дизельный компрессор <br/> AIRMAN PDS 185 SC</p> </Link>
                                <div className="gs__item__bottom-power">5 м³ в минуту</div>
                                <div className="gs__item__bottom-power">7.5 кг/см²</div>

                            </div>                                
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>

                        <div className="gS__container-item2">
                            <div className="gS__item__top">
                                <Link to='/Kompressor2' className="gs__item__top-link" onClick={handleButtonClick}>
                                    <img src={Kompressor2} className="gs__item__top-pic" alt="аренда AIRMAN PDS 265 SC"></img>
                                </Link>
                            </div> 
                            <div className="gS__item__bottom">
                                <Link to='/Kompressor2' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор  <br/>AIRMAN PDS 265 SC</p> </Link>
                                <div className="gs__item__bottom-power">7.5 м³ в минуту</div>
                                <div className="gs__item__bottom-power">7.5 кг/см²</div>
                                
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>
                        
                        <div className="gS__container-item2">
                            <div className="gS__item__top">
                                <Link to='/Kompressor7' className="gs__item__top-link" onClick={handleButtonClick}>
                                    <img src={Kompressor7} className="gs__item__top-pic" alt="аренда AIRMAN PDSF330DPC-W"></img>
                                </Link>
                            </div> 
                            <div className="gS__item__bottom">
                                <Link to='/Kompressor7' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор  <br/>AIRMAN PDSF330DPC-W</p> </Link>
                                <div className="gs__item__bottom-power">11 м³ в минуту</div>
                                <div className="gs__item__bottom-power">10.3 - 8 кг/см²</div>
                                
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>

                        <div className="gS__container-item2">
                            <div className="gS__item__top">
                                    <Link to='/Kompressor3' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor3} className="gs__item__top-pic" alt="аренда Airman PDS 390 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Kompressor3' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/>Airman PDS 390 SC-W</p></Link>
                                    <div className="gs__item__bottom-power">11 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">7.1 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item2">
                            <div className="gS__item__top">
                                    <Link to='/Kompressor4' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor4} className="gs__item__top-pic" alt="аренда Airman PDS 655 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Kompressor4' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/>Airman PDS 655 S</p></Link>
                                    <div className="gs__item__bottom-power">18.5 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">7.1 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item2">
                            <div className="gS__item__top">
                                    <Link to='/Kompressor5' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor5} className="gs__item__top-pic" alt="аренда Airman PDS400 S 6B5"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Kompressor5' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/>Airman PDS400 SAC</p></Link>
                                    <div className="gs__item__bottom-power">11 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">8 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item2">
                            <div className="gS__item__top">
                                    <Link to='/Kompressor6' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor6} className="gs__item__top-pic" alt="аренда AIRMAN 530 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Kompressor6' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/> AIRMAN 530 S</p></Link>
                                    <div className="gs__item__bottom-power">15 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">10.7 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>
                        <div className="gS__container-item2">
                            <div className="gS__item__top">
                                    <Link to='/Kompressor8' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor8} className="gs__item__top-pic" alt="аренда AIRMAN PDSF920S-W"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/Kompressor8' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/> AIRMAN PDSF920S-W-AC</p></Link>
                                    <div className="gs__item__bottom-power">26 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">10.3 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>  
                    </div>
                    <div className="generatory__text">
                    <p className="generatory__text-tittle">Для чего может понадобится дизельный компрессор</p>
                    <div className="generatory__pluslist">
                        <div className="generatory__pluslist-item">
                            <img className="generatory__text-img" src={for1} alt="Опрессовка трубопроводов"></img>
                            <p className="generatory__text-item">Опрессовка трубопроводов</p>
                        </div>
                        <div className="generatory__pluslist-item">
                            <img className="generatory__text-img" src={for2} alt="Заправка баллонов газом"></img>
                            <p className="generatory__text-item">Заправка баллонов газом</p>    
                        </div>
                        <div className="generatory__pluslist-item">
                            <img className="generatory__text-img" src={for3} alt="Демонтаж строительных
                            конструкций"></img>
                            <p className="generatory__text-item">Демонтаж строительных
                            конструкций</p>
                        </div>
                        <div className="generatory__pluslist-item">
                            <img className="generatory__text-img" src={for4} alt="Разрыхление почвы
                            и бурение скважин"></img>
                            <p className="generatory__text-item">Разрыхление почвы
                            и бурение скважин</p>
                        </div>
                        <div className="generatory__pluslist-item">
                            <img className="generatory__text-img" src={for5} alt="Пескоструйная очистка"></img>
                            <p className="generatory__text-item">Пескоструйная очистка</p>                            
                        </div>
                        <div className="generatory__pluslist-item"> 
                            <img className="generatory__text-img" src={for6} alt="Подготовка и подача
                            цементной смеси"></img>
                            <p className="generatory__text-item">Подготовка и подача
                            цементной смеси</p>
                        </div>
                    </div>
                    </div>
                    <div className="generatory__text">
                        <p className="generatory__text-tittle">Аренда компрессора</p>
                        <p className="generatory__text-item">
                            Аренда компрессора незаменима как в строительных и промышленных целях, ведь покупка дизельного компрессора не всегда целесообразна из-за достаточно высокой стоимости и определённых условий хранения.</p>
                        <p className="generatory__text-item">Для работ, не связанных с постоянной необходимостью в аппарате, аренда дизельного компрессора будет гораздо более удобным вариантом.</p>
                        <p className="generatory__text-item">Прокат техники является выгодной услугой по ряду причин:</p>
                        
                        <ul className="generatory__text-list">
                            <li className="generatory__text-item">техника доставляется к месту работы, устанавливается и подключается специалистами;</li>
                            <li className="generatory__text-item">нет необходимости в оборудовании места для хранения;</li>
                            <li className="generatory__text-item">отсутствие затрат на обслуживание и ремонт техники;</li>
                            <li className="generatory__text-item">в аренду предоставляются компрессоры ведущих производителей, все аппараты имеют высокую производительность;</li>
                            <li className="generatory__text-item">поломка при естественном износе оборудования устраняется за счёт компании.</li>
                        </ul>
                        <p className="generatory__text-item">Перед началом работ, необходимо проконсультироваться со специалистами компании «Машины и механизмы» по вопросам работы с техникой и мерам предосторожности.</p>

                    </div>

                </div>
                <Moreinfo/>
                     
                     <Popup  active={popupActive} setActive={setPopUpActive} />     
            </>
           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        )
    }


export default MainKompressori